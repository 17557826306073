import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { Weather, WeatherAdapter } from '../models/weather.model';
import { catchError, map } from 'rxjs/operators';
import { ApiError } from '../../../Library/api-data/api-error.model';
import {
    faSun,
    faSunCloud,
    faCloud,
    faClouds,
    faCloudShowersHeavy,
    faCloudSunRain,
    faCloudBolt,
    faSnowflake,
    faMoonStars,
    faCloudMoon,
    faCloudMoonRain,
    faCloudFog
} from '@fortawesome/pro-light-svg-icons';

@Injectable({
    providedIn: 'root'
})
export class WeatherService {

    url = environment.apiUrl + '/weather';
    private weather: BehaviorSubject<Weather> = new BehaviorSubject<Weather>(null);
    public weather$: Observable<Weather> = this.weather.asObservable();

    public iconMap: any = {
        '01d': faSun,
        '02d': faSunCloud,
        '03d': faCloud,
        '04d': faClouds,
        '09d': faCloudShowersHeavy,
        '10d': faCloudSunRain,
        '11d': faCloudBolt,
        '13d': faCloudSunRain,
        '50d': faSnowflake,
        '01n': faMoonStars,
        '02n': faCloudMoon,
        '03n': faCloud,
        '04n': faClouds,
        '09n': faCloudShowersHeavy,
        '10n': faCloudMoonRain,
        '11n': faCloudBolt,
        '13n': faSnowflake,
        '50n': faCloudFog
    };

    constructor(private http: HttpClient, private weatherAdapter: WeatherAdapter) {
    }


    getWeather(): Observable<Weather> {
        return this.http.get<Weather>(this.url).pipe(
            map(res => {
                const currentWeather = this.weatherAdapter.adapt(res);
                this.weather.next(currentWeather);
                return currentWeather;
            }),
            catchError(error => {
                error = new ApiError(error.error);
                return throwError(error);
            })
        );
    }

}
