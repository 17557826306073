import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import {
  Component,
  HostListener,
  Inject,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  PLATFORM_ID, Renderer2,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { interval, Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { LocaleService, RouteTranslation } from '../../../Library/services/locale.service';
import { GlobalService } from '../../../Library/services/global.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { MenuItem } from '../../../@modules/menu/models/menu-item.model';
import { MenuService } from '../../../@modules/menu/services/menu.service';
import { filter, take, tap } from 'rxjs/operators';
import { DetailService } from '../../../@modules/detail/services/detail.service';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class HeaderComponent implements OnInit, OnDestroy {
  faTimes = faTimes;
  routeTranslations: RouteTranslation;
  locale: string;
  ngUnsubscribe = new Subject();
  isMobileLayout: boolean = null;
  menuVisible: boolean = null;
  showRedirectWrapper: string = null;
  translationHomepage: SafeUrl;
  timeUntilRedirect: number = 5;
  headerMenu: MenuItem[];
  isServer: boolean = null;

  constructor(
    private localeService: LocaleService,
    private menuService: MenuService,
    private globalService: GlobalService,
    private detailService: DetailService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private platformId,
    @Inject(LOCALE_ID) public localeCode,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.locale = this.localeService.getLocale();
    this.localeService.currentRouteTranslations$.subscribe((routeTranslations: RouteTranslation) => {
      this.routeTranslations = routeTranslations;
    });
  }

  ngOnInit(): void {
    if (isPlatformServer(this.platformId)) {
      this.isServer = true;
    }
    if (isPlatformBrowser(this.platformId)) {
      this.renderer.removeClass(this.document.body, 'overflow-hidden');
      this.isMobileLayout = window.innerWidth <= 756;
      window.onresize = () => this.isMobileLayout = window.innerWidth <= 756;
    }

    this.detailService.details$.pipe(
      filter(result => result != null),
      tap(result => {
        this.headerMenu = result.additional.menus.main.items;
      }),
      take(1)
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  toggleMenuDialog(): void {
    this.menuVisible = !this.menuVisible;
    if (isPlatformBrowser(this.platformId)) {
      this.toggleOverflowClass();
    }
  }

  toggleLangDialog(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.toggleOverflowClass();
    }
  }

  toggleOverflowClass(): void {
    if (this.menuVisible) {
      this.renderer.addClass(this.document.body, 'overflow-hidden');
    } else {
      this.renderer.removeClass(this.document.body, 'overflow-hidden');
    }
  }

  redirectToHome(locale: string): void {
    if (this.showRedirectWrapper) {
      return;
    }

    this.showRedirectWrapper = locale;
    if (locale === 'me') {
      locale = '';
    }
    this.translationHomepage = this.sanitizer.bypassSecurityTrustUrl(environment.websiteUrl + '/' + locale);

    if (isPlatformServer(this.platformId)) {
      return;
    }

    const subscription = interval(1000)
      .subscribe(x => {
        this.timeUntilRedirect--;
        if (this.timeUntilRedirect === 1) {
          location.href = environment.websiteUrl + '/' + locale;
        }
      });
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent): void {
    if (event.keyCode === 27) {
      this.menuVisible = false;
    }
  }

}
