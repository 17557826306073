import { Component, Input, OnInit } from '@angular/core';
import { Slot } from '../../models/slot.model';


@Component({
    selector: 'app-slot-a100',
    templateUrl: './slot-a100.component.html',
    styleUrls: ['./slot-a100.component.scss']
})
export class SlotA100Component implements OnInit {

    @Input() slotable: Slot;

    constructor() {
    }

    ngOnInit(): void {
    }

}
