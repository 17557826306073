import { Injectable } from '@angular/core';
import { Resource } from '../../../Library/resource/resource.model';
import { BaseAdapter } from '../../../Library/adapter/adapter.model';
import { routes, currentLocale } from 'src/app/Library/routes';


export class MenuItem extends Resource<Request> {

    public position: number;
    public slug: string;
    public state: string;
    public title: string;
    public type: string;
    public url: string;
    public link: string;
    public query_param: any;
    public level: number;
    public menuable: any;
    public menuable_type: string;
    public menuable_id: number;

    public items: MenuItem[];

    public constructor(data?: {}) {
        super(data);
    }
}

@Injectable({
    providedIn: 'root'
})
export class MenuItemAdapter extends BaseAdapter<MenuItem> {

    constructor() {
        super(MenuItem);
    }

    adapt(data: any): MenuItem {
        const item = super.adapt(data);

        if (item.type === 'custom-url') {
            const splitURL = item.url.split('?');
            item.link = splitURL[0];
            item.query_param = this.getQueryParamFromURL(splitURL[1]);
        }

        if (item.type === 'placeholder') {
            item.link = '#';
        }

        if (item.type === 'page' && item.menuable) {
            item.url = item.menuable.path;
        }

        if (item.type === 'general-link') {
            item.link = this.getLinkLocale() + '/' + item.url;
        }

        if (item.items) {
            item.items = this.adaptArray(item.items);
        }

        return item;
    }

    private getLinkLocale(): string {
        return currentLocale === 'en' ? '' : '/' + currentLocale;
    }

    private getQueryParamFromURL(param: string): any {
        const query_param = {};
        if (param) {
            const splitParam = param.split('=');
            query_param[splitParam[0]] = splitParam[1];
        }
        return query_param;
    }
}
