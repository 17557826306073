import {Inject, Injectable, Injector, LOCALE_ID} from '@angular/core';
import {DateTime} from 'luxon';
import {Media, MediaAdapter} from '../../../Library/media/models/media.model';
import {Resource} from '../../../Library/resource/resource.model';
import {environment} from '../../../../environments/environment';
import {BaseAdapter} from '../../../Library/adapter/adapter.model';
import {Attachment, AttachmentAdapter} from '../../attachment/models/attachment.model';

export class Document extends Resource<Request> {
    public id: number;
    public title: string;
    public description: string;
    public filename: string;
    public serial_number: string;
    public mime_type: string;
    public byte_size: number;
    public size: string;
    public version: string;
    public slug: string;
    public node_ref: string;
    public published_at: string;
    public url: string;
    public hits: number;
    public downloads: number;
    public language: string;
    public rendition_fetch_tries: number;
    public status: string;
    public document_id: number;
    public document_type_id: number;
    public image_id: number;
    public image: Media;
    public creator_id: number;
    public created_at: string;
    public updated_at: string;
    public mimeType: string;
    public marker: string;
    public publishedAt: Date;
    public updatedAtDate: Date;
    public createdAtDate?: Date;
    public updatedAtData?: Date;
    public publishedAtDate?: Date;
    public languageAsObject: any;
    public pivot: any;
    public isSelected: boolean;
    public attachments: Attachment[];

    public seo_title?: string;
    public seo_fb_title?: string;
    public seo_tw_title?: string;
    public seo_description?: string;
    public seo_fb_description?: string;
    public seo_tw_description?: string;
    public seo_keywords?: string;
    public seo_image_url?: string;
    public seo_fb_image_url?: string;
    public seo_tw_image_url?: string;

    public toTransliterate = [
        'title',
        'description',
        'seo_title',
        'seo_fb_title',
        'seo_tw_title',
        'seo_description',
        'seo_fb_description',
        'seo_tw_description',
        'seo_keywords'
    ];

    public constructor(data?: {}) {
        super(data);
    }

    public getTranslation(locale: string): Document {
        const data = {
            slug: this.node_ref
        };

        return new Document(data);
    }

    public getDownloadUrl(): string {
        return `${environment.baseApiUrl}/download/${this.node_ref}?version=${this.version}`;
    }

    public getPreviewUrl(): string {
        return `${environment.baseApiUrl}/download-preview/${this.node_ref}?version=${this.version}`;
    }
}

@Injectable({
    providedIn: 'root'
})
export class DocumentAdapter extends BaseAdapter<Document> {

    constructor(
        private mediaAdapter: MediaAdapter,
        private attachmentAdapter: AttachmentAdapter,
        private injector: Injector,
        @Inject(LOCALE_ID) private localeCode: string
    ) {
        super(Document);
    }

    adapt(data: any): Document {
        const item = super.adapt(data);

        if (this.localeCode === 'sr-Latn-ME') {
            item.url = '/dokumenta/' + item.node_ref;
        } else {
            item.url = '/en/documents/' + item.node_ref;
        }


        if (item.byte_size) {
            item.size = this.formatBytes(item.byte_size, 0);
        }

        if (item.mime_type) {
            item.mimeType = this.formatMimeType(item.mime_type);
        }

        if (item.updated_at) {
            item.updatedAtDate = DateTime.fromSQL(item.updated_at, {zone: 'UTC'}).toJSDate();
        }

        if (item.created_at) {
            item.createdAtDate = DateTime.fromSQL(item.created_at, {zone: 'UTC'}).toJSDate();
        }

        if (item.published_at) {
            item.publishedAtDate = DateTime.fromSQL(item.published_at, {zone: 'UTC'}).toJSDate();
        }

        if (item.attachments) {
            item.attachments = this.attachmentAdapter.adaptArray(item.attachments);
            item.filename = item.attachments[0].filename;
        }

        if (!item.seo_title) {
            item.seo_title = item.title;
        }
        if (!item.seo_fb_title) {
            item.seo_fb_title = item.title;
        }
        if (!item.seo_tw_title) {
            item.seo_tw_title = item.title;
        }

        if (!item.seo_description) {
            item.seo_description = item.description;
        }
        if (!item.seo_fb_description) {
            item.seo_fb_description = item.description;
        }
        if (!item.seo_tw_description) {
            item.seo_tw_description = item.description;
        }

        item.seo_image_url = `${environment.websiteUrl}/assets/img/documents/document-module-placeholder-${this.formatMimeType(item.mime_type)}.png`;
        item.seo_fb_image_url = `${environment.websiteUrl}/assets/img/documents/document-module-placeholder-${this.formatMimeType(item.mime_type)}.png`;
        item.seo_tw_image_url = `${environment.websiteUrl}/assets/img/documents/document-module-placeholder-${this.formatMimeType(item.mime_type)}.png`;

        return item;
    }

    private formatMimeType(mimeType: string): string {
        switch (mimeType) {
            case 'application/pdf':
                return 'pdf';
            case 'application/zip':
                return 'zip';
            case 'application/msword':
                return 'doc';
            case 'application/vnd.ms-excel':
                return 'docx';
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                return 'xlsx';
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                return 'docx';
            default:
                return 'default';
        }
    }

    private formatBytes(bytes: number, decimals: number): string {
        if (bytes === 0) {
            return '0 Bytes';
        }

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
}
