import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Resource } from '../../../Library/resource/resource.model';
import { Media, MediaAdapter } from '../../../Library/media/models/media.model';
import { BaseAdapter } from '../../../Library/adapter/adapter.model';

export class Slot extends Resource<Request> {
    id: number;
    name: string;
    title: string;
    description?: string;
    slug: string;
    path: string;
    url: string;
    image: Media;
    microthumb: string;
    locale: string;
    publishedAtDate: Date;
    color?: string;
    startDate?: Date;
    seo_image?: Media;
    seo_title?: string;
    url_text?: string;
    slot_type?: string;

    public constructor(data?: {}) {
        super(data);
    }
}

@Injectable({
    providedIn: 'root'
})
export class SlotAdapter extends BaseAdapter<Slot> {

    constructor(
        private mediaAdapter: MediaAdapter,
        @Inject(LOCALE_ID) private localeCode: string
    ) {
        super(Slot);
    }

    adapt(data: any): Slot {
        const item = super.adapt(data);

        if (item.image) {
            item.image = this.mediaAdapter.adapt(item.image);
        }

        if (!item.image && item.seo_image) {
            item.image = this.mediaAdapter.adapt(item.seo_image);
        }


        return item;
    }
}
