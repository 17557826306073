import { Injectable } from '@angular/core';
import { BaseAdapter } from '../../../Library/adapter/adapter.model';
import { CurrentWeather, CurrentWeatherAdapter } from './current-weather.model';

export class Weather {
    is_adapted: boolean;
    lat?: string;
    lon?: string;
    timezone?: string;
    timezone_offset?: number;
    current?: CurrentWeather;

    constructor(data?: {}) {
        Object.assign(this, data);
    }
}

@Injectable({
    providedIn: 'root'
})
export class WeatherAdapter extends BaseAdapter<Weather> {
    constructor(private currentWeatherAdapter: CurrentWeatherAdapter) {
        super(Weather);
    }

    adapt(data: {}): Weather {
        const item = super.adapt(data);

        if (item.is_adapted) {
            return item;
        }

        if (item.current) {
            item.current = this.currentWeatherAdapter.adapt(item.current);
        }

        item.is_adapted = true;

        return item;
    }
}

