import { Component, Inject, LOCALE_ID, OnInit, PLATFORM_ID } from '@angular/core';
import { MenuItem } from '../../../@modules/menu/models/menu-item.model';
import { LocaleService } from '../../../Library/services/locale.service';
import { filter, take, tap } from 'rxjs/operators';
import { DetailService } from '../../../@modules/detail/services/detail.service';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { SubscribeService } from '../../services/subscribe.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

    currentYear: number;
    footerMenu: MenuItem[];
    form: FormGroup;
    semaphores: any = {
        displayValidationError: false,
        displaySubscribeError: false,
        displaySuccess: false
    };

    constructor(
        private subscribeService: SubscribeService,
        private localeService: LocaleService,
        private detailService: DetailService,
        @Inject(PLATFORM_ID) private platformId
    ) {
    }

    ngOnInit(): void {
        this.currentYear = new Date().getFullYear();

        this.detailService.details$.pipe(
            filter(result => result != null),
            tap(result => {
                this.footerMenu = result.additional.menus.footer.items;
            }),
            take(1)
        ).subscribe();

        this.initForm();
    }

    initForm(): void {
        this.form = new FormGroup({
            email: new FormControl('', [Validators.required, Validators.email])
        });
    }

    get email(): AbstractControl {
        return this.form.get('email');
    }

    subscribe(): void {

        if (!this.form.valid) {
            return;
        }


        this.subscribeService.subscribeToList(this.email.value)
            .subscribe(
                res => {
                    this.semaphores.displaySuccess = true;
                    if (isPlatformBrowser(this.platformId)) {
                        setTimeout(() => {
                            this.semaphores.displaySuccess = false;
                        }, 4000);
                    }
                },
                err => {
                    this.semaphores.displaySubscribeError = true;
                }
            );


    }
}
