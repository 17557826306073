import { AfterViewInit, Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { ContentModule } from '../../models/content-module.model';
import { isPlatformBrowser } from '@angular/common';
import Swiper, { Navigation } from 'swiper';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';


@Component({
    selector: 'app-content-module-a130',
    templateUrl: './content-module-a130.component.html',
    styleUrls: ['./content-module-a130.component.scss']
})
export class ContentModuleA130Component implements OnInit, AfterViewInit {

    @Input() contentModule: ContentModule;
    faArrowRight = faArrowRight;
    faArrowLeft = faArrowLeft;

    constructor(@Inject(PLATFORM_ID) private platformId) {
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            Swiper.use([Navigation]);
            const swiper = new Swiper('.swiper-a130' + this.contentModule.id, {
                modules: [Navigation],
                slidesPerView: 4,
                spaceBetween: 16,
                loop: true,
                loopedSlides: 6,
                watchSlidesProgress: true,
                breakpoints: {
                    360: {
                        slidesPerView: 1.25
                    },
                    768: {
                        slidesPerView: 3
                    },
                    1160: {
                        slidesPerView: 4
                    }
                },
                navigation: {
                    nextEl: '.nav-right',
                    prevEl: '.nav-left'
                }
            });

        }
    }

    updateOffset(swiper: Swiper): void {

    }

}
