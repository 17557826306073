<div class="w-full relative rounded-4 h-54 md:h-46 overflow-hidden relative group hover:cursor-pointer">
    <a *ngIf="slotable.url"
       [appFlLink]="slotable.url"
       class="absolute inset-0 w-full h-full z-20"></a>
    <div class="absolute inset-0">
        <img *ngIf="slotable.image"
             class="object-cover object-center w-full h-full"
             [src]="slotable.image.i_375x250"
             alt="{{ slotable.title }}">
        <img *ngIf="!slotable.image"
             class="object-cover object-center w-full h-full"
             src="assets/img/default/img_default_375x250.jpg"
             alt="{{ slotable.title }}">
    </div>

    <div class="absolute inset-0 bg-gradient-to-b from-[#00000080] opacity-100 sm:opacity-0 sm:group-hover:opacity-100 transition-opacity duration-500 ease-in-out"></div>
    <div class="absolute inset-0 bg-gradient-to-t from-[#00000080] opacity-0 sm:opacity-100 sm:group-hover:opacity-0 transition-opacity duration-500 ease-in-out"></div>

    <div *ngIf="slotable.title"
         class="absolute inset-0 flex items-end text-white font-700 text-18 z-10">
        <span class="p-4 underline decoration-transparent group-hover:decoration-white transition-colors duration-500 ease-in-out">
			{{ slotable.title }}
		</span>
    </div>
</div>
