export const environment: any = {
  production: false,
  staging: true,
  apiUrl: 'https://cycttesjcp-api.fabrika.me/v1',
  baseApiUrl: 'https://cycttesjcp-api.fabrika.me',
  websiteUrl: 'https://cycttesjcp.fabrika.me',
  mediaUrl: 'https://storage-dev.medialib.dev/media/',
  recaptchaSiteKey: '6Lf-ak0jAAAAAKuAXgF0cqxcOZLQfaGzCWxNDcbF',
  mailChimpEndpoint: 'https://fleka.us14.list-manage.com/subscribe/post-json?u=900bc09fcff279240663fd3f7&amp;id=bf542b4186'
};
