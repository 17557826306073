<section class="w-full py-10">
	<div class="container flex flex-col  space-y-12 md:space-y-0 md:flex-row">
		<div *ngFor="let slotable of contentModule.slotables"
			class="w-full md:w-9/24 md:mx-2/24"
		>
			<app-slot-a160 [slotable]="slotable"></app-slot-a160>

		</div>
	</div>
</section>
