import { Component, Inject, Input, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { MenuItem } from '../../../@modules/menu/models/menu-item.model';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
    faChevronRight, faEarthEurope, faArrowRight
} from '@fortawesome/pro-regular-svg-icons';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';

@Component({
    selector: 'app-header-mobile',
    templateUrl: './header-mobile.component.html',
    styleUrls: ['./header-mobile.component.scss']
})
export class HeaderMobileComponent implements OnInit {

    @Input() headerMenu: MenuItem [];
    disableButton: boolean = false;
    menuVisible: boolean = false;
    menuTransition: boolean = false;
    isMobileLayout: boolean = null;
    timer: any = null;
    faChevronRight = faChevronRight;
    semaphores: any = {
        submenuId: -1
    };
    faEarthEurope = faEarthEurope;
    faChevronLeft = faChevronLeft;
    faArrowRight = faArrowRight;
    currentRoute: string;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private renderer: Renderer2,
        @Inject(DOCUMENT) private document: Document,
        @Inject(PLATFORM_ID) private platformId) {
    }

    ngOnInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.renderer.removeClass(this.document.body, 'overflow-hidden');
            this.isMobileLayout = window.innerWidth <= 768;
            window.onresize = () => this.isMobileLayout = window.innerWidth <= 768;
        }

        this.currentRoute = this.router.url;

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart && isPlatformBrowser(this.platformId) && this.menuVisible) {
                this.document.getElementById('menu').classList.toggle('change');
                this.menuTransition = !this.menuTransition;
                this.disableButton = true;
                this.timer = setTimeout(() => {
                    this.disableButton = false;
                    this.menuVisible = false;
                    this.semaphores.submenuId = -1;
                    this.toggleOverflowClass();
                }, 1000);
            }
            if (event instanceof NavigationEnd) {
                this.currentRoute = event.url;
            }
        });
    }

    toggleMenuDialog(): void {
        if (this.disableButton) {
            return;
        }
        if (isPlatformBrowser(this.platformId)) {
            this.document.getElementById('menu').classList.toggle('change');
            this.menuTransition = !this.menuTransition;
            if (this.menuVisible) {
                this.disableButton = true;
                this.timer = setTimeout(() => {
                    this.disableButton = false;
                    this.menuVisible = false;
                    this.semaphores.submenuId = -1;
                    this.toggleOverflowClass();
                }, 1000);
            } else {
                this.menuVisible = true;
                this.toggleOverflowClass();
            }
        }
    }

    toggleOverflowClass(): void {
        if (this.menuVisible) {
            this.renderer.addClass(this.document.body, 'overflow-hidden');
        } else {
            this.renderer.removeClass(this.document.body, 'overflow-hidden');
        }
    }

    onMenuItemClick(index: number, item: MenuItem): void {
        this.semaphores.submenuId = index;
    }

    back(): void {
        this.semaphores.submenuId = -1;
    }

    navigateToSameRoute(): void {
        this.router.navigate([this.currentRoute], {
            relativeTo: this.route
        });
    }

}
