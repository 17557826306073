import { Component, Input, OnInit } from '@angular/core';
import { ContentModule } from '../../models/content-module.model';

@Component({
  selector: 'app-content-module-a150',
  templateUrl: './content-module-a150.component.html',
  styleUrls: ['./content-module-a150.component.scss']
})
export class ContentModuleA150Component implements OnInit {

  @Input() contentModule: ContentModule;

  constructor() { }

  ngOnInit(): void {
  }

}
