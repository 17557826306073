<div class="h-full flex flex-col justify-center items-start text-gray-black">
	<h3 *ngIf="slotable.title"
		class="font-700 text-30 md:text-36 leading-122"> {{ slotable.title }}</h3>
	<div *ngIf="slotable.description"
		 class="md:mt-2 text-gray-500 text-14 leading-140"
		 [innerHTML]="slotable.description"></div>
	<a *ngIf="slotable.url"
	   [appFlLink]="slotable.url"
	   class="w-full mt-6 justify-center items-center md:w-auto px-4 py-3 flex border border-gray-300 rounded-18 group hover:bg-gray-black hover:cursor-pointer self-start">
		<span class="text-16 leading-120 font-700 group-hover:text-white">{{ slotable.url_text ? slotable.url_text : 'Learn More' }}</span>
		<fa-icon [icon]="faArrowRight" class="ml-4 w-3 h-3 flex justify-center items-center group-hover:text-white"></fa-icon>
	</a>

</div>

