<section class="w-full flex flex-col text-gray-black overflow-hidden">
	<div class="w-full container">
		<h2 *ngIf="!contentModule.name_hidden" class="text-30 md:text-36 leading-122 font-700 mb-8 text-left">{{ contentModule.name }}</h2>
	</div>
	<div class="container swiper {{'swiper-a100' + contentModule.id}} relative overflow-visible mx-auto">
		<div class="w-full swiper-wrapper">
			<div *ngFor="let slotable of contentModule.slotables, let index = index;" class="swiper-slide">
				<app-slot-a100 [slotable]="slotable"></app-slot-a100>
			</div>
		</div>

		<button class="hidden md:flex z-10 nav-left absolute top-[75px] left-[-52px] w-9 h-9 bg-gray-black  justify-center items-center
					bg-opacity-70 hover:bg-opacity-90 rounded-l-2 flex items-center justify-center transition-colors ease-linear duration-400">
			<fa-icon [icon]="faArrowLeft" class="w-4 h-4 text-white flex justify-center items-center"></fa-icon>
		</button>

		<button class="hidden md:flex z-10 nav-right absolute top-[75px] right-[-52px] w-9 h-9 bg-gray-black justify-center items-center
						bg-opacity-70 hover:bg-opacity-90 rounded-r-2 flex items-center justify-center transition-colors ease-linear duration-400">
			<fa-icon [icon]="faArrowRight" class="w-4 h-4 text-white flex justify-center items-center"></fa-icon>
		</button>
	</div>


</section>
