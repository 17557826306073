<section class="w-full py-10">
	<div class="container flex flex-col items-center">
		<div class="w-full md:w-14/24 flex justify-center">
			<div *ngFor="let slotable of contentModule.slotables"
				 class="w-full h-full">
				<app-slot-a150 [slotable]="slotable"></app-slot-a150>
			</div>
		</div>
	</div>
</section>
