<div class="w-full pt-8 pb-6 bg-gradient-nomads sm:rounded-4 text-white relative overflow-hidden">
    <div class="relative z-10 max-w-[360px] sm:max-w-max sm:pr-[140px] sm:pl-8 mx-auto sm:mx-0">
		<span *ngIf="slotable.title" class="block text-30 sm:text-36 leading-122 font-700">
			{{ slotable.title }}
		</span>
        <span *ngIf="slotable.description"
              class="block mt-4 text-18 leading-122"
              [innerHTML]="slotable.description">
		</span>
        <a *ngIf="slotable.url" [appFlLink]="slotable.url" class="block group mt-6 flex items-center">
			<span class="text-16 leading-115 font-700 underline decoration-transparent sm:group-hover:decoration-white transition-colors duration-400 ease-linear">
				<span *ngIf="slotable.url_text">
					{{ slotable.url_text }}
				</span>
				<span *ngIf="!slotable.url_text" i18n="@@learn_more">
					Learn more
				</span>
			</span>
            <div class="ml-4 w-8 h-8 border border-white sm:group-hover:border-gray-black rounded-full sm:group-hover:bg-gray-black flex items-center justify-center transition-colors duration-400 ease-linear">
                <fa-icon [icon]="faChevronRight" class="w-2 h-3 flex items-center"></fa-icon>
            </div>
        </a>
    </div>

    <div class="absolute inset-0">
        <picture class="w-full h-full">
            <source srcset="assets/img/module_graphics/gfx_a030_a031_mobile.svg" media="(max-width: 768px)">
            <source srcset="assets/img/module_graphics/gfx_a030_a031.svg" media="(min-width: 768px)">
            <img class="w-full sm:w-auto h-full object-cover object-left"
                 srcset="assets/img/module_graphics/gfx_a030_a031_mobile.svg"
                 alt="">
        </picture>
    </div>
</div>
