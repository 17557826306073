import { Component, Input, OnInit } from '@angular/core';
import { ContentModule } from '../../models/content-module.model';
import { Weather } from '../../../weather/models/weather.model';
import { WeatherService } from '../../../weather/services/weather.service';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'app-content-module-a030',
    templateUrl: './content-module-a030.component.html'
})
export class ContentModuleA030Component implements OnInit {

    @Input() contentModule: ContentModule;
    weather: Weather;
    semaphores: any = {
        loadingWeather: false
    };
    icon: any;

    constructor(private weatherService: WeatherService) {
    }

    ngOnInit(): void {
        this.semaphores.loadingWeather = true;
        this.weatherService.weather$.pipe(
            filter(weather => weather !== null)
        ).subscribe(
            (weather: Weather) => {
                this.weather = weather;
                this.icon = this.weatherService.iconMap[this.weather.current.weather[0].icon];
                this.semaphores.loadingWeather = false;
            },
            err => {
                this.semaphores.loadingWeather = false;
            }
        );
    }

}
