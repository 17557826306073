import { Component, Input, OnInit } from '@angular/core';
import { Slot } from '../../models/slot.model';

@Component({
  selector: 'app-slot-a150',
  templateUrl: './slot-a150.component.html',
  styleUrls: ['./slot-a150.component.scss']
})
export class SlotA150Component implements OnInit {

  @Input() slotable: Slot;

  constructor() { }

  ngOnInit(): void {
  }

}
