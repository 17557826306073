<div class="w-full bg-white z-120 fixed top-0 h-14 border-b border-gray-100">
    <div class="container flex justify-between py-4 items-center">
        <a routerLink="/" class="block">
            <img src="assets/img/logos/logo_mobile.png"
                 width="370"
                 height="48"
                 alt="Digital Nomads"
                 title="Digital Nomads"
                 class="w-[185px] h-6">
        </a>
        <button class="menu-button" id="menu" (click)="toggleMenuDialog()">
            <div class="strip strip1"></div>
            <div class="strip strip2" id="strip2"></div>
            <div class="strip strip3"></div>
        </button>

    </div>
    <div [ngClass]="menuTransition ? 'h-grow' : 'h-0'"
         class="fixed flex grow flex-col top-14 z-10 w-full bg-white
  				transition-height duration-1000 ease-in-out overflow-y-auto">
        <div *ngIf="semaphores.submenuId === -1" class="w-full h-grow relative overflow-hidden">
            <div class="container">
                <ul class="mt-12 flex flex-col space-y-12">
                    <li *ngFor="let item of headerMenu;let i = index">
                        <button (click)="onMenuItemClick(i, item)"
                                class="w-full flex items-center justify-between font-400 text-gray-black leading-120"
                        >{{ item.title }}
                            <fa-icon [icon]="faChevronRight" class="w-3 h-3 flex justify-center items-center"></fa-icon>
                        </button>
                    </li>
                </ul>

                <a [routerLink]="['/','article','legal-status-for-nomads']"
                   class="w-full mt-14 py-4 bg-han flex justify-center font-700 text-white text-16 leading-120 items-center rounded-18"
                   i18n="@@apply_now">
                    Apply Now
                </a>
            </div>
            <div class="flex flex-col justify-end text-gray-500 absolute left-0 right-0 bottom-0">
                <div class="bg-gray-100 flex flex-col justify-center items-center pt-6 pb-10 space-y-6 max-h-32">
                    <span class="text-12 leading-120">
						Official Website of the Government of Montenegro
					</span>
                </div>
            </div>
        </div>

        <div [ngClass]="{'open-menu':semaphores.submenuId !== -1}"
             class="mt-10 container flex flex-col items-start font-800 transition-all duration-500 ui-secondary-menu w-full">
            <div *ngIf="semaphores.submenuId !== -1"
                 class="flex justify-start">
                <button (click)="back()">
                    <fa-icon [icon]="faChevronLeft" class="w-3 h-3 flex justify-center items-center"></fa-icon>
                </button>
                <div class="ml-4 text-28 leading-120">{{ headerMenu[semaphores.submenuId].title }}</div>
            </div>
            <ul *ngIf="semaphores.submenuId !== -1 && headerMenu[semaphores.submenuId]?.items?.length"
                class="mt-8 flex flex-col w-full space-y-2">
                <li *ngFor="let item of headerMenu[semaphores.submenuId].items"
                    class="flex justify-between items-center py-4">
                    <a *ngIf="!item.items || !item.items.length"
                       [appFlLink]="item.url"
                       class="flex justify-between items-center w-full text-18 leading-120"
                    >
                        {{ item.title }}
                        <fa-icon [icon]="faArrowRight" class="w-4 h-4 flex justify-center items-center"></fa-icon>
                    </a>
                    <ul *ngIf="item.items && item.items.length" class="flex flex-col font-400 text-gray-500 w-full">
                        <li class="text-18 font-700 leading-120 py-4 text-gray-black">{{ item.title }}</li>
                        <li *ngFor="let subitem of item.items"
                            class="flex justify-between items-center w-full pl-6">
                            <a *ngIf="subitem.type !== 'placeholder'"
                               [appFlLink]="subitem.url"
                               class="flex justify-between items-center w-full text-16 leading-120">
                                {{ subitem.title }}
                                <fa-icon [icon]="faArrowRight"
                                         class="w-4 h-4 flex justify-center items-center py-4"></fa-icon>
                            </a>
                            <a *ngIf="subitem.type === 'placeholder'"
                               (click)="navigateToSameRoute()"
                               class="flex justify-between items-center w-full text-16 leading-120">
                                {{ subitem.title }}
                                <fa-icon [icon]="faArrowRight"
                                         class="w-4 h-4 flex justify-center items-center py-4"></fa-icon>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>

        </div>
    </div>
</div>
