<section class="w-full max-w-max mx-auto h-34 sm:h-30 bg-gradient-nomads py-10 sm:py-8 relative overflow-hidden mt-[-80px] md:mt-[-120px]">
	<div class="container relative z-10">
		<h2 *ngIf="contentModule.name"
			class="text-center font-700 text-28 md:text-30 leading-120 text-white">{{ contentModule.name }}</h2>
	</div>
	<div class="absolute inset-0">
		<picture class="w-full h-full">
            <source srcset="assets/img/module_graphics/gfx_a120_a121_mobile.svg" media="(max-width: 768px)">
            <source srcset="assets/img/module_graphics/gfx_a120_121.svg" media="(min-width: 768px)">
            <img class="w-full h-full object-cover object-center"
                 srcset="assets/img/module_graphics/gfx_a120_a121_mobile.svg"
                 alt="">
        </picture>
	</div>
</section>
