import {Component, Input, OnInit} from '@angular/core';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-slot-a30',
  templateUrl: './slot-a30.component.html'
})
export class SlotA30Component implements OnInit {
  faChevronRight = faChevronRight;
  @Input() slotable: any;

  constructor() {
  }

  ngOnInit(): void {
  }

}
