import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-slot-a091',
    templateUrl: './slot-a091.component.html'
})
export class SlotA091Component implements OnInit {

    @Input() slotable: any;

    constructor() {
    }

    ngOnInit(): void {
    }

}
