<section
        class="w-full max-w-max h-[664px] mx-auto flex justify-center items-center sm:h-[600px] relative overflow-hidden">
    <div *ngFor="let slotable of contentModule.slotables" class="w-full h-full z-10">
        <app-slot-a20 [slotable]="slotable"></app-slot-a20>
    </div>

    <div *ngIf="contentModule.image && !contentModule.video_url" class="absolute inset-0">
        <img appImgFallback="assets/img/default/img_default_1920x1080.jpg"
             srcset="{{ contentModule.image.i_254x451 }} 254w, {{ contentModule.image.i_1920x1080 }} 1920w"
             sizes="(max-width: 768px) 254px, (min-width: 768px) 1920px"
             [src]="contentModule.image.i_1920x1080"
             class="object-center object-cover w-full h-full"
             alt="Digital Nomads">
    </div>

    <div *ngIf="contentModule.video_url" class="absolute inset-0">
        <video
                *ngIf="contentModule.video_url"
                #videoPlayer
                class="flex m-none absolute inset-0 object-cover object-center"
                loop playsinline="" preload="none" [muted]="true" [autoplay]="true"
                data-src="{{ contentModule.video_url }}">
            <source data-src="{{ contentModule.video_url }}" type="video/mp4">
        </video>
    </div>

    <div *ngIf="!contentModule.image && !contentModule.video_url"
         class="absolute inset-0">
        <img appImgFallback="assets/img/default/img_default_1920x1080"
             srcset="assets/img/default/img_default_254x451.jpg 254w, assets/img/default/img_default_1920x1080.jpg 1920w"
             sizes="(max-width: 768px) 254px, (min-width: 768px) 1920px"
             src="assets/img/default/img_default_1920x1080.jpg"
             class="object-center object-cover w-full h-full"
             alt="Digital Nomads">
    </div>

    <div class="absolute inset-0">
        <picture class="w-full h-full object-cover object-center">
            <source srcset="assets/img/module_graphics/gfx_a010_mobile.svg" media="(max-width: 768px)">
            <source srcset="assets/img/module_graphics/gfx_a010.svg" media="(min-width: 768px)">
            <img class="w-full h-full object-cover object-center"
                 srcset="assets/img/module_graphics/gfx_a010_mobile.svg"
                 alt="">
        </picture>
    </div>
</section>
