<div class="w-full bg-gray-100 flex flex-row md:flex-col justify-between items-center md:justify-center p-4 tet-gray-dark group relative
			hover:bg-han hover:text-white rounded-3 font-700 md:items-start">
	<a *ngIf="slotable.url" [appFlLink]="slotable.url" class="absolute inset-0 z-10 w-full h-full"></a>
	<h2 *ngIf="slotable.title" class="text-22">{{ slotable.title}}</h2>
	<div class="md:mt-6 flex items-center">
		<span class="hidden md:block group-hover:underline text-14"> {{ slotable.url_text ? slotable.url_text : 'Learn More' }}</span>
        <div class="w-10 h-10 rounded-full flex justify-center ml-2 items-center bg-transparent group-hover:bg-white sm:group-hover:ml-4 transition-all duration-500 ease-in-out relative">
            <fa-icon [icon]="faArrowRight" class="w-3 text-gray-black"></fa-icon>
        </div>
	</div>
</div>
