import { Component, Input, OnInit } from '@angular/core';
import { ContentModule } from '../../models/content-module.model';
import { faStar } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-content-module-a140',
  templateUrl: './content-module-a140.component.html',
  styleUrls: ['./content-module-a140.component.scss']
})
export class ContentModuleA140Component implements OnInit {

  @Input() contentModule: ContentModule;
  faStar = faStar;

  constructor() { }

  ngOnInit(): void {
  }

}
