import { Injectable } from '@angular/core';
import { BaseAdapter } from '../../../Library/adapter/adapter.model';

export class WeatherDescription {
    is_adapted: boolean;
    id?: number;
    main?: string;
    description?: string;
    icon?: string;

    constructor(data?: {}) {
        Object.assign(this, data);
    }

}

@Injectable({
    providedIn: 'root'
})
export class WeatherDescriptionAdapter extends BaseAdapter<WeatherDescription> {
    constructor() {
        super(WeatherDescription);
    }

    adapt(data: {}): WeatherDescription {

        const item = super.adapt(data);

        if (item.is_adapted) {
            return item;
        }


        item.is_adapted = true;

        return item;

    }
}

