import { Inject, Injectable, Injector, LOCALE_ID } from '@angular/core';
import { Resource } from '../../../Library/resource/resource.model';
import { Media, MediaAdapter } from '../../../Library/media/models/media.model';
import { ContentModule, ContentModuleAdapter } from '../../content-module/models/content-module.model';
import { PageBreadcrumb, PageBreadcrumbAdapter } from './page-breadcrumb.model';
import { BaseAdapter } from '../../../Library/adapter/adapter.model';
import { HasSeoItem } from '../../../Library/services/seo/has-seo-item';
import { SeoItem } from '../../../Library/services/seo/seo-item.model';
import Helpers from '../../../Library/helpers';
import { environment } from '../../../../environments/environment';

export class Page extends Resource<Request> implements HasSeoItem {
  locale: string;
  id: number;
  title: string;
  image: Media;
  breadcrumbs: PageBreadcrumb[];
  modules: ContentModule[];
  url: string;
  path: string;
  slug: string;
  color: string;
  translations?: Page[];
  is_adapted: boolean = false;

  // SEO
  seo_image_id?: number;
  seo_image_url?: string;
  seo_image?: Media;
  seo_title?: string;
  seo_description?: string;
  seo_keywords?: string;
  seo_is_auto: boolean;

  seo_fb_image_id?: number;
  seo_fb_image?: Media;
  seo_fb_image_url?: string;
  seo_fb_title?: string;
  seo_fb_description?: string;
  seo_fb_keywords?: string;
  seo_fb_is_auto: boolean;

  seo_tw_image_id?: number;
  seo_tw_image?: Media;
  seo_tw_image_url?: string;
  seo_tw_title?: string;
  seo_tw_description?: string;
  seo_tw_keywords?: string;
  seo_tw_is_auto: boolean;

  // SEO END

  public constructor(data?: {}) {
    super(data);

    this.init();
  }

  init(): void {
    if (this.locale === 'en') {
      this.url = '/en/' + this.path;
    } else {
      this.url = '/' + this.path;
    }

    if (!this.modules) {
      this.modules = [];
    }
  }

  getTranslation(locale: string): Page | null {
    if (this.locale === locale) {
      return this;
    }

    let result = null;

    if (this.translations) {
      this.translations.forEach((translation) => {
        if (translation.locale === locale) {
          result = translation;
        }
      });
    }

    return result;
  }

  getSeoItem(): SeoItem {

    const title = this.seo_title ? this.seo_title : this.title;

    let description = this.seo_description;
    if (!description && this.description) {
      description = this.description.replace(/<\/?[^>]+(>|$)/g, '').substr(0, 160);
    }


    const seoItem = new SeoItem({
      title,
      description,
      seo_fb_title: this.seo_fb_title ? this.seo_fb_title : title,
      seo_fb_description: this.seo_fb_description ? this.seo_fb_description : description,
      seo_fb_type: 'website',
      seo_tw_title: this.seo_tw_title ? this.seo_tw_title : title,
      seo_tw_type: 'website',
      url: environment.websiteUrl + '/' + this.slug
    });

    return Helpers.attachSeoImages(this, seoItem);
  }

}

@Injectable({
  providedIn: 'root',
})
export class PagesAdapter extends BaseAdapter<Page> {

  constructor(
    private mediaAdapter: MediaAdapter,
    private injector: Injector,
    private pageBreadcrumbAdapter: PageBreadcrumbAdapter,
    @Inject(LOCALE_ID) private locale,
  ) {
    super(Page);
  }

  adapt(data: any): Page {
    const item = super.adapt(data);

    if (item.is_adapted) {
      return item;
    }

    if (item.modules) {
      // The injector is used to avoid circular dependency
      // in case the Page module slot can display a Page
      const contentModuleAdapter = this.injector.get<ContentModuleAdapter>(ContentModuleAdapter);
      item.modules = contentModuleAdapter.adaptArray(item.modules);
    }

    if (item.breadcrumbs && item.breadcrumbs.length > 0) {
      item.breadcrumbs = this.pageBreadcrumbAdapter.adaptArray(item.breadcrumbs);
    }

    if (item.translations) {
      item.translations = this.adaptArray(item.translations);
    }

    if (item.seo_image) {
      item.seo_image = this.mediaAdapter.adapt(item.seo_image);
    }

    if (item.seo_fb_image) {
      item.seo_fb_image = this.mediaAdapter.adapt(item.seo_fb_image);
    }

    if (item.seo_tw_image) {
      item.seo_tw_image = this.mediaAdapter.adapt(item.seo_tw_image);
    }

    item.is_adapted = true;

    return item;
  }
}
