<div class="container mx-auto h-full flex flex-col text-white relative">
	<div class="h-full w-full md:w-16/24 flex flex-col justify-center items-start">
		<h1 *ngIf="slotable.title" class="text-white font-700 text-42 md:text-48 leading-111">{{ slotable.title }}</h1>
		<span *ngIf="slotable.description"
			  class="font-400 mt-2 md:mt-4 text-20 leading-120"
			  [innerHTML]="slotable.description"></span>
		<button *ngIf="slotable.url"
			 [appFlLink]="slotable.url"
			 class="bg-white rounded-18 flex justify-center items-center w-full md:w-auto py-3 px-4 text-gray-black
			 		mt-6 md:mt-9 font-700 hover:bg-gray-black hover:text-white transition-colors duration-500 ease-in-out">
			{{ slotable.url_text ? slotable.url_text : 'Learn More' }}
		</button>
	</div>
	<div class="absolute bottom-10 left-0 right-0 font-400 w-full flex justify-between text-white text-16 md:text-24">
		<span class="my-auto">{{ today | date : 'hh:mm a' }}</span>
		<div *ngIf="!semaphores.loadingWeather"
			 class="flex items-center">
			<span class="mr-6 w-7 h-7 md:w-10 md:h-10 flex justify-center items-center">
				<fa-icon [icon]="icon" class="w-full h-full text-white"></fa-icon>
			</span>
			<span class="h-15 border-l-4 pl-6 border-han">{{ weather.current.temp | number : '1.0-0' }}°C</span>
		</div>
		<div *ngIf="semaphores.loadingWeather"
			 class="flex items-center">
			<app-loader></app-loader>
		</div>
	</div>
</div>
