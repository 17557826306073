<section class="w-full sm:h-100 flex flex-col sm:flex-row justify-center">
	<div class="order-first sm:order-last sm:w-large max-w-large md:mx-auto relative h-full">
		<div class="sm:absolute sm:right-0 sm:top-0 sm:bottom-0 sm:h-full module-a61-img">
			<img *ngIf="contentModule.image"
				 srcset="{{contentModule.image.i_375x250}} 375w, {{ contentModule.image.i_1056x594 }} 1056w"
				 sizes="(max-width: 1160px) 375px, (min-width: 1160px) 1056px"
				 [src]="contentModule.image.i_1056x594"
				 class="object-cover object-center w-full h-full"
				 alt="{{ contentModule.name }}">
			<img *ngIf="!contentModule.image"
				 srcset="assets/img/default/img_default_375x250.jpg 375w, assets/img/default/img_default_712x400.jpg 712w"
				 sizes="(max-width: 1160px) 375px, (min-width: 1160px) 712px"
				 src="assets/img/default/img_default_712x400.jpg"
				 class="object-cover object-center w-full h-full"
				 alt="{{ contentModule.name }}">
		</div>
		<div class="container mx-auto h-full">
			<div class="order-last sm:order-first sm:mr-14/24 w-full sm:w-10/24 h-full mt-6 sm:mt-0">
				<div *ngFor="let slotable of contentModule.slotables" class="w-full h-full">
					<app-slot-a061 [slotable]="slotable"></app-slot-a061>
				</div>
			</div>
		</div>
	</div>

</section>
