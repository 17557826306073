import {Component, Input, OnInit} from '@angular/core';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { Slot } from '../../models/slot.model';

@Component({
  selector: 'app-slot-a60',
  templateUrl: './slot-a60.component.html',
  styleUrls: ['./slot-a60.component.scss']
})
export class SlotA60Component implements OnInit {

  @Input() slotable: Slot;
  faArrowRight = faArrowRight;

  constructor() { }

  ngOnInit(): void {
  }

}
