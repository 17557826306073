<div *ngIf="item" [style]="style">
	<div *ngFor="let group of groupedModules;let i = index"
		 class="w-full my-20 md:my-30"
		 [ngClass]="{ 'bg-gray-300' : group.isHighlighted , 'my-20 md:my-30': i > 0,'my-20 md:my-30': i > 0, 'mt-14 md:mt-26': i === 0 }">
		<div [ngClass]="{ 'container flex flex-col sm:flex-row flex-wrap': !group.hasFixedWidth }">
			<div *ngFor="let module of group.modules" class="flex {{ module.column_width }}"
				 [ngClass]="{ 'hidden sm:flex' : module.mobile_hidden, 'sm:hidden' : module.desktop_hidden }">
				<app-content-module class="w-full" [contentModule]="module"></app-content-module>
			</div>
		</div>
	</div>
</div>
