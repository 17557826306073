import { SeoItem } from '../../../Library/services/seo/seo-item.model';

export const seoRules404 = {
    en: new SeoItem({
        title: 'Page Not Found',
        description: 'Page Not Found',
        seo_title: 'Page Not Found',
        seo_fb_title: 'Page Not Found',
        seo_fb_description: 'Page Not Found',
        seo_fb_image_url: '/assets/img/seo/gfx_seo_en_facebook_1200x630.jpg',
        seo_description: 'Page Not Found',
        seo_tw_title: 'Page Not Found',
        seo_tw_description: 'Page Not Found',
        seo_tw_image_url: '/assets/img/seo/gfx_seo_en_twitter_1200x675.jpg',
        seo_fb_type: 'website',
        seo_keywords: 'Music, Center, Montenegro',
        url: '',
    })
};
