import { Component, Input, OnInit } from '@angular/core';
import {
    faClouds,
    faMapLocationDot,
    faCircleQuestion,
    faForkKnife
} from '@fortawesome/pro-light-svg-icons';


@Component({
    selector: 'app-slot-a110',
    templateUrl: './slot-a110.component.html'
})
export class SlotA110Component implements OnInit {

    @Input() slotable: any;
    @Input() slot_type: string;
    faClouds = faClouds;
    faMapLocationDot = faMapLocationDot;
    faCircleQuestion = faCircleQuestion;
    faForkKnife = faForkKnife;
    icon: null;

    const;
    icons = {
        clouds: this.faClouds,
        'map-location-dot': this.faMapLocationDot,
        'circle-question': this.faCircleQuestion,
        'fork-knife': this.faForkKnife
    };

    constructor() {
    }

    ngOnInit(): void {
        this.icon = this.icons[this.slotable.icon];
    }

}
