import { Component, Input, OnInit } from '@angular/core';
import { ContentModule } from '../../models/content-module.model';

@Component({
  selector: 'app-content-module-a121',
  templateUrl: './content-module-a121.component.html'
})
export class ContentModuleA121Component implements OnInit {

  @Input() contentModule: ContentModule;

  constructor() { }

  ngOnInit(): void {
  }

}
