import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServerResponseService } from 'src/app/Library/server-response.service';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { SeoService } from '../../../Library/services/seo/seo.service';
import { SeoItem } from '../../../Library/services/seo/seo-item.model';
import { seoRules404 } from '../../../@modules/routing/seo-rules/seo-rules-404';
import { currentLocale } from '../../../Library/routes';

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html'
})
export class NotFoundComponent implements OnInit, AfterViewInit {

    faArrowRight = faArrowRight;

    constructor(
        private seoService: SeoService,
        private srs: ServerResponseService,
        private router: Router
    ) {
        srs.setNotFound();
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        this.seoService.refresh(new SeoItem(seoRules404[currentLocale]));
    }
}
