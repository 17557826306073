import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Resource } from '../../../Library/resource/resource.model';
import { Media, MediaAdapter } from '../../../Library/media/models/media.model';
import { BaseAdapter } from '../../../Library/adapter/adapter.model';

export class ArticleSlot extends Resource<Request> {
    id: number;
    name: string;
    title: string;
    description?: string;
    slug: string;
    path: string;
    url: string;
    image: Media;
    locale: string;
    url_text?: string;
    intro_content?: string;

    seo_image: Media;
    seo_title: string;

    public constructor(data?: {}) {
        super(data);
    }
}

@Injectable({
    providedIn: 'root'
})
export class ArticleSlotAdapter extends BaseAdapter<ArticleSlot> {

    constructor(
        private mediaAdapter: MediaAdapter,
        @Inject(LOCALE_ID) private localeCode: string
    ) {
        super(ArticleSlot);
    }
}
