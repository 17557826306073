import { Component, Input, OnInit } from '@angular/core';
import { ContentModule } from '../../models/content-module.model';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';


@Component({
    templateUrl: './content-module-a090.component.html'
})
export class ContentModuleA090Component implements OnInit {

    @Input() contentModule: ContentModule;
    faArrowRight = faArrowRight;

    ngOnInit(): void {
    }

}
