<section class="container relative my-22 md:mt-48 flex flex-col items-center">
	<iframe src="https://giphy.com/embed/5Zesu5VPNGJlm" width="350.55" height="218" class="pointer-events-none"></iframe>
	<div class="mt-14 w-full sm:mx-4/24 flex flex-col items-center">
		<h1 class="text-36 sm:text-48 leading-121 sm:leading-110 font-700" i18n="@@404_whoops">
			Whoops!
		</h1>
		<span class="mt-6 text-18 sm:text-20 leading-122 text-gray-500 text-center" i18n="@@404_message">
			No worries! That just 404 error page.<br>
			We can’t seem to find the page you’re looking for.
		</span>
		<a class="block group mt-12 px-[18px] py-2 rounded-18 flex items-center justify-center border border-gray-300 sm:hover:border-gray-black no-underline bg-white sm:hover:text-white sm:hover:bg-gray-black transition-colors duration-400 ease-linear" href="/">
			<span class="text-16 font-700 sm:group-hover:text-white transition-colors duration-400 ease-linear" i18n="@@404_back_to_homepage">
				Back me to homepage
			</span>
		</a>
	</div>
</section>
