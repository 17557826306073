<section class="max-w-large mx-auto flex flex-col md:flex-row justify-center md:justify-start items-center relative">

	<div class="aspect-[3/2] md:aspect-video w-full md:w-[1056px] relative overflow-hidden">
		<img *ngIf="contentModule.image"
			 appImgFallback="assets/img/default/img_default_1056x594.jpg"
			 srcset="{{ contentModule.image.i_375x250 }} 254w, {{ contentModule.image.i_1056x594 }} 1056w"
			 sizes="(max-width: 768px) 375px, (min-width: 768px) 1056w"
			 class="absolute inset-0 object-cover object-center w-full h-full"
			 [src]="contentModule.image.i_1056x594"
			 alt="Digital Nomads">
		<img *ngIf="!contentModule.image"
			 appImgFallback=""
			 srcset="assets/img/default/img_default_375x250.jpg 254w, assets/img/default/img_default_1056x594.jpg 1056w"
			 sizes="(max-width: 768px) 375px, (min-width: 768px) 1056w"
			 class="absolute inset-0 object-cover object-center w-full h-full"
			 src="assets/img/default/img_default_1056x594.jpg"
			 alt="Digital Nomads">

		<div class="absolute inset-0">
            <picture class="w-full md:w-auto h-full object-cover object-center md:object-left">
                <source srcset="assets/img/module_graphics/gfx_a040_041_mobile.svg" media="(max-width: 1160px)">
                <source srcset="assets/img/module_graphics/gfx_a040_041.svg" media="(min-width: 1160px)">
                <img class="w-full md:w-auto h-full object-cover object-center md:object-left"
                     srcset="assets/img/module_graphics/gfx_a040_041_mobile.svg"
                     alt="">
            </picture>
        </div>
	</div>
	<div class="md:absolute md:inset-0 w-full h-full flex justify-center z-20">
		<div class="w-full md:container md:mx-auto flex items-center justify-end">
			<div *ngFor="let slotable of contentModule.slotables"
				 class="bg-gradient-nomads w-full md:w-11/24 md:rounded-4">
				<app-slot-a041 [slotable]="slotable"></app-slot-a041>
			</div>
		</div>
	</div>

</section>

