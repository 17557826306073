<footer class="w-full " id="footer">

    <div class="w-full bg-gray-black pt-10">
        <div class="container flex flex-col">
            <div class="w-full flex flex-col justify-center md:flex-row md: justify-between items-center pb-10 border-b border-gray-100">
                <a routerLink="/" class="block">
                    <img src="assets/img/logos/footer_logo.png"
                         width="458"
                         height="64"
                         alt="Digital Nomads"
                         title="Digital Nomads"
                         class="w-[230px] h-[30px]">
                </a>
                <a [routerLink]="['/','article','legal-status-for-nomads']" i18n="@@footer_apply_now"
                   class="mt-6 md:mt-0 w-full md:w-auto flex justify-center text-16 font-700 leading-120 text-gray-black font-700 items-center px-4 py-3 flex bg-white border border-gray-300
					   rounded-18 hover:text-white hover:bg-gray-black hover:cursor-pointer">
                    Apply Now
                </a>
            </div>
            <ul *ngIf="footerMenu && footerMenu.length"
                class="w-full md:w-20/24 flex flex-col md:flex-row pt-10 md:py-12">
                <li *ngFor="let item of footerMenu; let i = index"
                    class="md:mt-0 w-full flex flex-col md:w-8/24" [ngClass]="{'mt-16': i }">
                    <a *ngIf="item.type != 'placeholder'"
                       [appFlLink]="item.url"
                       class="font-700 text-16 text-center md:text-left leading-120 text-gray-100"
                    >{{ item.title }}</a>
                    <span *ngIf="item.type === 'placeholder'"
                          class="font-700 text-16 text-center md:text-left leading-120 text-gray-100"
                    >{{ item.title }}</span>
                    <ul *ngIf="item.items && item.items.length"
                        class="w-full flex flex-col text-gray-300 font-400 mt-6 space-y-6">
                        <li *ngFor="let subitem of item.items" class="w-full flex justify-center md:justify-start">
                            <a *ngIf="subitem.type !== 'placeholder'"
                               [appFlLink]="subitem.url"
                               class="text-16 leading-120 text-center md:text-left"
                            >{{ subitem.title }}</a>
                            <span *ngIf="subitem.type === 'placeholder'"
                                  class="text-16 leading-120 text-center md:text-left"
                            >{{ subitem.title }} </span>
                        </li>
                    </ul>
                </li>
                <li class="w-full flex flex-col items-center md:w-8/24">
                    <span class="font-700 text-16 leading-120 text-gray-100 mt-16 text-center md:text-left md:mt-0 md:self-start">Subscribe</span>
                    <div class="flex flex-col w-full mt-6 ">
                        <form [formGroup]="form" (ngSubmit)="subscribe()" class="flex flex-col">
                            <input type="text"
                                   id="subscribe"
                                   class="w-full rounded-2 max-w-70 mx-auto md:mx-0 placeholder:text-gray-300 placeholder:text-14"
                                   placeholder="Enter your email"
                                   formControlName="email">
                            <button type="submit"
                                    class="mt-6 w-full max-w-70 md:w-auto flex justify-center text-16 font-700 leading-120 text-white font-700 items-center px-4 py-2 md:py-3 flex
								border border-gray-100 hover:border-transparent rounded-18 hover:cursor-pointer mx-auto md:mx-0 md:self-start"
                                    i18n="@@footer_apply_now">
                                Apply Now
                            </button>
                            <span *ngIf="semaphores.displaySuccess"
                                  class="text-status-success font-700 text-center md:text-left mt-2">Thank you for subscribing!</span>
                            <span *ngIf="semaphores.displaySubscribeError"
                                  class="text-status-error font-700 text-center md:text-left mt-2">An error occured</span>
                            <span *ngIf="email.touched && !email.valid"
                                  class="text-status-error font-700 text-center md:text-left mt-2">Invalid email</span>
                        </form>
                        <div class="mt-8 md:mt-6 font-700 text-16 leading-120 text-gray-100 text-center md:text-left">
                            Contact Us
                        </div>
                        <a href="mailto:contact@digitalnomads.me"
                           class="mt-8 md:mt-6 text-white font-400 text-16 leading-120 text-center mb-10 md:mb-0 md:text-left">contact@digitalnomads.me</a>
                    </div>
                </li>
            </ul>
        </div>
    </div>

    <div class="bg-white flex flex-col">
        <div class="container flex flex-col">
            <div class="w-full flex flex-col md:flex-row md:justify-between items-start md:items-center py-6">
                <img src="assets/img/logos/gov_footer_logo.png"
                     class="w-[155px] h-[47px]"
                     width="310"
                     height="94" alt="Digital Nomads">
                <span class="text-gray-300 text-14 leading-130 mt-10 md:mt-0">
                    Copyright © {{ currentYear }} DigitalNomads
                </span>
            </div>
        </div>
        <div class="w-full h-px bg-gray-200"></div>
        <div class="container flex flex-col">
            <div class="w-full flex flex-col md:flex-row md:justify-between items-start md:items-center py-6">
                <span class="text-gray-300 text-14 md:w-14/24">
                    This website is protected by reCAPTCHA and applies Google
                    <a href="#" target="_blank" class="underline">privacy policy</a>  and
                    <a href="#" target="_blank" class="underline">terms of condition</a>. <br>
                    <a href="https://www.gov.me/" target="_blank" class="underline font-700">Official Website of the Government of Montenegro</a></span>
                <span class="text-gray-300 text-14 leading-130 mt-10 md:mt-0">
                     Made with 💜 by <a href="https://www.fleka.me/" target="_blank">fleka</a>
                </span>
            </div>
        </div>
    </div>

</footer>
