import { Component, Input, OnInit } from '@angular/core';
import { ContentModule } from '../../models/content-module.model';

@Component({
  selector: 'app-content-module-a041',
  templateUrl: './content-module-a041.component.html',
})
export class ContentModuleA041Component implements OnInit {

  @Input() contentModule: ContentModule;

  constructor() { }

  ngOnInit(): void {
  }

}
