import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class MediaConfigurationService {

    private config = {
        mediaInit: {
            sizes: {
                article: [
                    {name: 'i_375x250', width: 375, height: 250},
                    {name: 'i_1920x1280', width: 1920, height: 1280},
                    {name: 'i_1056x594', width: 1056, height: 594},
                    {name: 'i_572x382', width: 572, height: 382},
                    {name: 'i_866x577', width: 866, height: 577},
                    {name: 'i_712x400', width: 712, height: 400},
                    {name: 'i_1200x675', width: 1200, height: 675},
                    {name: 'i_1200x630', width: 1200, height: 630},
                    {name: 'i_375x664', width: 375, height: 664},
                    {name: 'i_254x451', width: 254, height: 451},
                    {name: 'thumbnail', width: 572, height: 382},
                    {name: 'default', width: 1920, height: 1280},
                    {name: 'google', width: 1200, height: 630},
                    {name: 'facebook', width: 1200, height: 630},
                    {name: 'twitter', width: 1200, height: 675}
                ],
                module: [
                    {name: 'i_1920x1280', width: 1920, height: 1280},
                    {name: 'i_1920x1080', width: 1920, height: 1080},
                    {name: 'i_1200x675', width: 1200, height: 675},
                    {name: 'i_1200x630', width: 1200, height: 630},
                    {name: 'i_1056x594', width: 1056, height: 594},
                    {name: 'i_375x250', width: 375, height: 250},
                    {name: 'i_375x664', width: 375, height: 664},
                    {name: 'i_254x451', width: 254, height: 451},
                    {name: 'thumbnail', width: 375, height: 250},
                    {name: 'default', width: 1920, height: 1280},
                    {name: 'google', width: 1200, height: 630},
                    {name: 'facebook', width: 1200, height: 630},
                    {name: 'twitter', width: 1200, height: 675}
                ]
            }
        }
    };

    public getConfig(name: string): any {
        if (!name || (name && !this.config[name])) {
            return null;
        }

        return this.config[name];
    }
}
