import { Component, Input, OnInit } from '@angular/core';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { Slot } from '../../models/slot.model';

@Component({
    selector: 'app-slot-a50',
    templateUrl: './slot-a50.component.html',
    styleUrls: ['./slot-a50.component.scss']
})
export class SlotA50Component implements OnInit {

    @Input() slotable: Slot;
    faArrowRight = faArrowRight;

    constructor() {
    }

    ngOnInit(): void {
    }

}
