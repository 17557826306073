import {Inject, Injectable, Injector, LOCALE_ID} from '@angular/core';
import {Media, MediaAdapter} from 'src/app/Library/media/models/media.model';
import {Resource} from '../../../Library/resource/resource.model';
import {Author, AuthorAdapter} from '../../author/models/author.model';
import {ArticleContent, ArticleContentAdapter} from './article-content.model';
import {environment} from '../../../../environments/environment';
import {DateTime} from 'luxon';
import {BaseAdapter} from '../../../Library/adapter/adapter.model';
import {HasSeoItem} from '../../../Library/services/seo/has-seo-item';
import Helpers from 'src/app/Library/helpers';
import {SeoItem} from '../../../Library/services/seo/seo-item.model';
import {ArticleContentModule, ArticleContentModuleAdapter} from '../../article-content-module/models/article-content-module.model';
import {Document, DocumentAdapter} from '../../document/models/document.model';

export class Article extends Resource<Request> implements HasSeoItem {
    public id?: number;
    public locale: string;
    public author?: Author;
    public title?: string;
    public content?: ArticleContent;
    public slug?: string;
    public url?: string;
    public image_id?: number;
    public image?: Media;
    public microthumb: string;
    public alt: string;
    public image_title: string;
    public status?: string;
    public toc_items: any[];
    public published_at?: string;
    public publishedAtDate?: Date;
    public publishedAtSchemaDate?: string;
    public updated_at: string;
    public updatedAtDate?: Date;
    public updatedAtSchemaDate?: string;
    public intro_content: string;
    public related: Article[];
    public modules: ArticleContentModule[];
    public documents: Document[];
    public translations: Article[];
    public is_adapted: boolean = false;

    // SEO
    seo_image_id?: number;
    seo_image_url?: string;
    seo_image?: Media;
    seo_title?: string;
    seo_description?: string;
    seo_keywords?: string;
    seo_is_auto: boolean;

    seo_fb_image_id?: number;
    seo_fb_image?: Media;
    seo_fb_image_url?: string;
    seo_fb_title?: string;
    seo_fb_description?: string;
    seo_fb_keywords?: string;
    seo_fb_is_auto: boolean;

    seo_tw_image_id?: number;
    seo_tw_image?: Media;
    seo_tw_image_url?: string;
    seo_tw_title?: string;
    seo_tw_description?: string;
    seo_tw_keywords?: string;
    seo_tw_is_auto: boolean;

    // SEO END

    public constructor(data?: Partial<Article>) {
        super(data);
    }

    public getTranslation(locale: string): Article | null {

        if (this.locale === locale) {
            return this;
        }

        let result = null;
        if (this.translations) {
            this.translations.forEach((translation) => {
                if (translation.locale === locale) {
                    result = translation;
                }
            });
        }

        return result;
    }

    getUrl(locale?: string): string {
        if (!locale) {
            locale = this.locale;
        }

        switch (locale) {
            case 'en':
                return 'article/' + this.slug;
            default:
                return 'clanak/' + this.slug;
        }
    }

    getSeoItem(): SeoItem {

        const title = this.seo_title ? this.seo_title : this.title;

        let description = this.seo_description;
        if (!description && this.description) {
            description = this.description.replace(/<\/?[^>]+(>|$)/g, '').substr(0, 160);
        }


        const seoItem = new SeoItem({
            title,
            description,
            seo_fb_title: this.seo_fb_title ? this.seo_fb_title : title,
            seo_fb_description: this.seo_fb_description ? this.seo_fb_description : description,
            seo_fb_type: 'website',
            seo_tw_title: this.seo_tw_title ? this.seo_tw_title : title,
            seo_tw_type: 'website',
            url: environment.websiteUrl + '/' + this.getUrl()
        });

        return Helpers.attachSeoImages(this, seoItem);
    }

}

@Injectable({
    providedIn: 'root'
})
export class ArticleAdapter extends BaseAdapter<Article> {

    constructor(
        private articleContentAdapter: ArticleContentAdapter,
        private moduleAdapter: ArticleContentModuleAdapter,
        private authorAdapter: AuthorAdapter,
        private mediaAdapter: MediaAdapter,
        private documentAdapter: DocumentAdapter,
        private injector: Injector,
        @Inject(LOCALE_ID) private localeCode: string
    ) {
        super(Article);
    }

    adapt(data: any): Article {
        const item = super.adapt(data);

        if (item.is_adapted) {
            return item;
        }

        // TODO: add translation of urls
        if (item.locale === 'me') {
            item.url = '/me/clanak/' + item.slug;
        } else {
            item.url = '/article/' + item.slug;
        }

        if (item.seo_image_url && !item.seo_image_url.startsWith('http')) {
            item.seo_image_url = environment.mediaUrl + item.seo_image_url;
        }


        if (item.content) {
            item.content = this.articleContentAdapter.adapt(item.content);
        }

        if (item.content) {
            item.toc_items = [];
            const regexp = /<h([1-4]) id="([a-zA-Z0-9]+)".*?>(.+?\n?)<\/h[1-4]>/g;
            let match = regexp.exec(item.content.data);
            while (match != null) {
                const toc_item = {
                    id: match[2],
                    text: match[3],
                    level: parseInt(match[1], 10) - 1
                };
                item.toc_items.push(toc_item);
                match = regexp.exec(item.content.data);
            }
        }

        if (item.documents) {
            item.documents = this.documentAdapter.adaptArray(item.documents);
        }

        if (item.author) {
            item.author = this.authorAdapter.adapt(item.author);
        }

        if (item.related && item.related) {
            item.related = this.adaptArray(item.related);
        }

        if (item.image) {
            item.image = this.mediaAdapter.adapt(item.image);

            if (item.image_title) {
                item.alt = item.image_title;
            } else if (!item.image_title && item.image.caption) {
                item.alt = item.image.caption;
            } else {
                item.alt = item.title;
            }
        }

        if (!item.image && item.seo_image) {
            item.image = this.mediaAdapter.adapt(item.seo_image);
        }

        if (item.modules) {
            item.modules = this.moduleAdapter.adaptArray(item.modules);
        }

        if (item.published_at) {
            item.publishedAtDate = DateTime.fromSQL(item.published_at, {zone: 'UTC'}).toJSDate();
            item.publishedAtSchemaDate = DateTime.fromSQL(item.published_at, {zone: 'UTC'}).toFormat('y-LL-dd');
        }

        if (item.updated_at) {
            item.updatedAtDate = DateTime.fromSQL(item.updated_at, {zone: 'UTC'}).toJSDate();
            item.updatedAtSchemaDate = DateTime.fromSQL(item.updated_at, {zone: 'UTC'}).toFormat('y-LL-dd');
        }

        if (item.translations) {
            item.translations = this.adaptArray(item.translations);
        }

        if (item.seo_image) {
            item.seo_image = this.mediaAdapter.adapt(item.seo_image);
        }

        if (item.seo_fb_image) {
            item.seo_fb_image = this.mediaAdapter.adapt(item.seo_fb_image);
        }

        if (item.seo_tw_image) {
            item.seo_tw_image = this.mediaAdapter.adapt(item.seo_tw_image);
        }

        item.is_adapted = true;

        return item;
    }
}

export class ExtendedArticle extends Article {
    public rowIndex?: number;
}

