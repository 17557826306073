import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SubscribeService {
    mailChimpEndpoint = environment.mailChimpEndpoint;

    constructor(
        private http: HttpClient
    ) {
    }

    subscribeToList(email): Observable<any> {
        const params = new HttpParams()
            .set('EMAIL', email);
        const mailChimpUrl = `${this.mailChimpEndpoint}&${params.toString()}`;
        return this.http.jsonp(mailChimpUrl, 'c');
    }
}
