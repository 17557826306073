<div class="w-full h-full group relative rounded-4 overflow-hidden">
    <a *ngIf="slotable.url" [appFlLink]="slotable.url" class="absolute inset-0 w-full h-full z-10"></a>
    <div class="aspect-square md:h-[451px] w-full bg-black overflow-hidden group">
        <img *ngIf="slotable.image"
             class="w-full h-full rounded-4 object-cover object-center sm:group-hover:scale-125 transition-all duration-500 ease-in-out"
             appImgFallback="assets/img/default/img_default_375x664.jpg"
             [src]="slotable.image.i_375x664"
             [alt]="slotable.title">
        <img *ngIf="!slotable.image"
             class="w-full h-full rounded-4 object-cover object-center sm:group-hover:scale-125 transition-all duration-500 ease-in-out"
             src="assets/img/default/img_default_375x664.jpg"
             alt="{{ slotable.title }}">

        <div class="absolute inset-0 bg-gradient-to-b from-[#00000080] opacity-100 sm:opacity-0 sm:group-hover:opacity-100 transition-opacity duration-500 ease-in-out"></div>
        <div class="absolute inset-0 bg-gradient-to-t from-[#00000080] opacity-0 sm:opacity-100 sm:group-hover:opacity-0 transition-opacity duration-500 ease-in-out"></div>

        <div class="absolute inset-0 pb-6 px-4 flex flex-col justify-end">
            <div class="w-full flex items-center justify-between text-white">
				<span class="text-24 leading-122 font-700">
					{{ slotable.title }}
				</span>
                <fa-icon [icon]="faArrowRight" class="w-6 sm:mr-2 sm:group-hover:mr-0 transition-[margin-right] duration-500 ease-in-out"></fa-icon>
            </div>
        </div>
    </div>
</div>


