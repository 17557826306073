<div class="w-full bg-white z-120 fixed top-0 h-14 border-b border-gray-100">
	<div class="container flex justify-between py-4 items-center">
		<a routerLink="/" class="block">
			<img src="assets/img/logos/logo_mobile.png"
				 width="370"
				 height="48"
				 alt="Digital Nomads"
				 title="Digital Nomads"
				 class="w-[185px] h-6">
		</a>
		<button class="menu-button" id="menu">
			<div class="strip strip1"></div>
			<div class="strip strip2" id="strip2"></div>
			<div class="strip strip3"></div>
		</button>

	</div>
</div>
