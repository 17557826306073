import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { Weather } from '../../../weather/models/weather.model';
import { Slot } from '../../models/slot.model';
import { WeatherService } from '../../../weather/services/weather.service';
import { filter } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-slot-a20',
    templateUrl: './slot-a20.component.html',
    styleUrls: ['./slot-a20.component.scss']
})
export class SlotA20Component implements OnInit {

    @Input() slotable: Slot;
    faArrowRight = faArrowRight;
    today = new Date();
    weather: Weather;
    semaphores: any = {
        loadingWeather: false
    };
    icon: any;

    constructor(
        @Inject(PLATFORM_ID) private platformId,
        private weatherService: WeatherService) {
    }

    ngOnInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            setInterval(() => {
                this.today = new Date();
            }, 1);
        }
        this.semaphores.loadingWeather = true;
        this.weatherService.weather$.pipe(
            filter(weather => weather !== null)
        ).subscribe(
            (weather: Weather) => {
                this.weather = weather;
                this.icon = this.weatherService.iconMap[this.weather.current.weather[0].icon];
                this.semaphores.loadingWeather = false;
            },
            err => {
                this.semaphores.loadingWeather = false;
            }
        );
    }

}
