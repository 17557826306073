import { SeoItem } from '../../../Library/services/seo/seo-item.model';

export const seoRulesDefault = {
  me: new SeoItem({
    title: 'Digital Nomads',
    description: 'Official website of the Montenegrin Government for the digital nomads that want to visit, or are currently residing in Montenegro.',
    seo_title: 'Digital Nomads',
    seo_fb_title: 'Digital Nomads',
    seo_fb_description: 'Digital Nomads description fb',
    seo_fb_image_url: '/assets/img/seo/gfx_seo_en_facebook_1200x630.jpg',
    seo_fb_type: 'website',
    seo_tw_title: 'Digital Nomads',
    seo_tw_description: 'Digital Nomads description tw',
    seo_tw_image_url: '/assets/img/seo/gfx_seo_en_twitter_1200x675.jpg',
    seo_keywords: 'Digital, Nomads, Montenegro',
    url: '',
  }),
  en: new SeoItem({
    title: 'Digital Nomads',
    description: 'Official website of the Montenegrin Government for the digital nomads that want to visit, or are currently residing in Montenegro.',
    seo_title: 'Digital Nomads',
    seo_fb_title: 'Digital Nomads',
    seo_fb_description: 'Official website of the Montenegrin Government for the digital nomads that want to visit, or are currently residing in Montenegro.',
    seo_fb_image_url: '/assets/img/seo/gfx_seo_en_facebook_1200x630.jpg',
    seo_description: 'Digital Nomads',
    seo_tw_title: 'Digital Nomads',
    seo_tw_description: 'Official website of the Montenegrin Government for the digital nomads that want to visit, or are currently residing in Montenegro.',
    seo_tw_image_url: '/assets/img/seo/gfx_seo_en_twitter_1200x675.jpg',
    seo_fb_type: 'website',
    seo_keywords: 'Digital, Nomads, Montenegro',
    url: '',
  })
};

