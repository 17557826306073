import {Component, Input, OnInit} from '@angular/core';
import {ContentModule} from '../../models/content-module.model';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'app-content-module-a050',
  templateUrl: './content-module-a050.component.html',
})
export class ContentModuleA050Component implements OnInit {

  @Input() contentModule: ContentModule;
  faArrowRight = faArrowRight;

  constructor() { }

  ngOnInit(): void {
  }

}
