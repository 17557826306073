<section class="w-full flex flex-col text-gray-black overflow-hidden">
	<div class="w-full container">
		<h2 *ngIf="!contentModule.name_hidden"
			class="text-30 md:text-36 leading-122 font-700 mb-8 text-left">{{ contentModule.name }}</h2>
	</div>
	<div class="hidden md:flex container swiper {{'swiper-a130' + contentModule.id}} relative">
		<div class="w-full flex swiper-wrapper">
			<div *ngFor="let slotable of contentModule.slotables, let index = index;" class="swiper-slide">
				<app-slot-a130 [slotable]="slotable"></app-slot-a130>
			</div>
		</div>
		<span class="absolute left-[-52px] h-full flex items-center">
			<button class="hidden md:flex z-10 nav-left w-9 h-9 bg-gray-black justify-center items-center
									bg-opacity-70 hover:bg-opacity-90 rounded-l-2 flex items-center justify-center transition-colors ease-linear duration-400">
				<fa-icon [icon]="faArrowLeft" class="w-4 h-4 text-white flex justify-center items-center"></fa-icon>
			</button>
		</span>

		<span class="absolute right-[-52px] h-full flex items-center">
			<button class="hidden md:flex z-10 nav-right w-9 h-9 bg-gray-black justify-center items-center
									bg-opacity-70 hover:bg-opacity-90 rounded-r-2 flex items-center justify-center transition-colors ease-linear duration-400">
				<fa-icon [icon]="faArrowRight" class="w-4 h-4 text-white flex justify-center items-center"></fa-icon>
			</button>
		</span>

	</div>

	<div class="flex md:hidden container flex-col space-y-4">
		<div *ngFor="let slotable of contentModule.slotables">
			<app-slot-a130 [slotable]="slotable"></app-slot-a130>
		</div>
	</div>

</section>

