import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../@shared/shared.module';

import { EnquiriesService } from '../../Library/contact/services/enquiries.service';
import { ReactiveFormsModule } from '@angular/forms';

import { ContentModuleDirective } from './directives/content-module.directive';
import { ContentModuleComponent } from './components/content-module.component';
import { ContentModuleA010Component } from './components/content-module-a010/content-module-a010.component';
import { ContentModuleA020Component } from './components/content-module-a020/content-module-a020.component';
import { ContentModuleA030Component } from './components/content-module-a030/content-module-a030.component';
import { ContentModuleA031Component } from './components/content-module-a031/content-module-a031.component';
import { ContentModuleA040Component } from './components/content-module-a040/content-module-a040.component';
import { ContentModuleA041Component } from './components/content-module-a041/content-module-a041.component';
import { ContentModuleA050Component } from './components/content-module-a050/content-module-a050.component';
import { ContentModuleA060Component } from './components/content-module-a060/content-module-a060.component';
import { ContentModuleA061Component } from './components/content-module-a061/content-module-a061.component';
import { ContentModuleA070Component } from './components/content-module-a070/content-module-a070.component';
import { ContentModuleA080Component } from './components/content-module-a080/content-module-a080.component';
import { ContentModuleA081Component } from './components/content-module-a081/content-module-a081.component';
import { ContentModuleA090Component } from './components/content-module-a090/content-module-a090.component';
import { ContentModuleA091Component } from './components/content-module-a091/content-module-a091.component';
import { ContentModuleA100Component } from './components/content-module-a100/content-module-a100.component';
import { ContentModuleA110Component } from './components/content-module-a110/content-module-a110.component';
import { ContentModuleA120Component } from './components/content-module-a120/content-module-a120.component';
import { ContentModuleA121Component } from './components/content-module-a121/content-module-a121.component';
import { ContentModuleA130Component } from './components/content-module-a130/content-module-a130.component';
import { ContentModuleA140Component } from './components/content-module-a140/content-module-a140.component';
import { ContentModuleA150Component } from './components/content-module-a150/content-module-a150.component';
import { ContentModuleA160Component } from './components/content-module-a160/content-module-a160.component';
import { SlotA10Component } from './components/slot-a10/slot-a10.component';
import { SlotA11Component } from './components/slot-a11/slot-a11.component';
import { SlotA20Component } from './components/slot-a20/slot-a20.component';
import { SlotA30Component } from './components/slot-a30/slot-a30.component';
import { SlotA31Component } from './components/slot-a31/slot-a31.component';
import { SlotA40Component } from './components/slot-a40/slot-a40.component';
import { SlotA041Component } from './components/slot-a041/slot-a041.component';
import { SlotA50Component } from './components/slot-a50/slot-a50.component';
import { SlotA60Component } from './components/slot-a60/slot-a60.component';
import { SlotA061Component } from './components/slot-a061/slot-a061.component';
import { SlotA70Component } from './components/slot-a70/slot-a70.component';
import { SlotA80Component } from './components/slot-a80/slot-a80.component';
import { SlotA081Component } from './components/slot-a081/slot-a081.component';
import { SlotA90Component } from './components/slot-a90/slot-a90.component';
import { SlotA091Component } from './components/slot-a091/slot-a091.component';
import { SlotA100Component } from './components/slot-a100/slot-a100.component';
import { SlotA110Component } from './components/slot-a110/slot-a110.component';
import { SlotA120Component } from './components/slot-a120/slot-a120.component';
import { SlotA130Component } from './components/slot-a130/slot-a130.component';
import { SlotA140Component } from './components/slot-a140/slot-a140.component';
import { SlotA150Component } from './components/slot-a150/slot-a150.component';
import { SlotA160Component } from './components/slot-a160/slot-a160.component';
import { RouterModule } from '@angular/router';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';


@NgModule({
    declarations: [
        ContentModuleDirective,
        ContentModuleComponent,
        ContentModuleA010Component,
        ContentModuleA020Component,
        ContentModuleA030Component,
        ContentModuleA031Component,
        ContentModuleA040Component,
        ContentModuleA041Component,
        ContentModuleA050Component,
        ContentModuleA060Component,
        ContentModuleA061Component,
        ContentModuleA070Component,
        ContentModuleA080Component,
        ContentModuleA081Component,
        ContentModuleA090Component,
        ContentModuleA091Component,
        ContentModuleA100Component,
        ContentModuleA110Component,
        ContentModuleA120Component,
        ContentModuleA121Component,
        ContentModuleA130Component,
        ContentModuleA140Component,
        ContentModuleA150Component,
        ContentModuleA160Component,
        SlotA10Component,
        SlotA11Component,
        SlotA20Component,
        SlotA30Component,
        SlotA40Component,
        SlotA50Component,
        SlotA60Component,
        SlotA70Component,
        SlotA80Component,
        SlotA90Component,
        SlotA100Component,
        SlotA091Component,
        SlotA31Component,
        SlotA041Component,
        SlotA061Component,
        SlotA081Component,
        SlotA110Component,
        SlotA120Component,
        SlotA130Component,
        SlotA140Component,
        SlotA150Component,
        SlotA160Component
    ],
    imports: [
        CommonModule,
        SharedModule,
        ReactiveFormsModule,
        RouterModule,
        YouTubePlayerModule,
        FontAwesomeModule
    ],
    exports: [
        ContentModuleComponent
    ],
    providers: [
        EnquiriesService
    ]
})
export class ContentModuleModule {
    constructor() {
    }
}
