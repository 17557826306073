import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { faArrowRight, faChevronDown, faEnvelope } from '@fortawesome/pro-light-svg-icons';
import { faEarthEurope } from '@fortawesome/free-solid-svg-icons';
import { MenuItem } from '../../../@modules/menu/models/menu-item.model';
import { NavigationStart, Router } from '@angular/router';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-header-desktop',
    templateUrl: './header-desktop.component.html',
    styleUrls: ['./header-desktop.component.scss']
})
export class HeaderDesktopComponent implements OnInit {

    @Input() headerMenu: MenuItem [];

    faEnvelope = faEnvelope;
    faEarthEurope = faEarthEurope;
    faChevronDown = faChevronDown;
    faArrowRight = faArrowRight;

    sempahores: any = {
        openedMenuId: -1
    };

    constructor(
        private router: Router,
        @Inject(PLATFORM_ID) private platformId,
        @Inject(DOCUMENT) private document: Document) {
    }

    ngOnInit(): void {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                if (isPlatformBrowser(this.platformId) && this.sempahores.openedMenuId !== -1) {
                    this.closeMenu(this.sempahores.openedMenuId);
                }
            }
        });
    }

    openMenu(i: number): void {
        if (this.sempahores.openedMenuId !== -1) {
            this.closeMenu(this.sempahores.openedMenuId);
        }
        if (isPlatformBrowser(this.platformId)) {
            const megaMenu = this.document.querySelector(`.ui-mega-menu-${i}`) as HTMLElement;
            const menuBackdrop = this.document.querySelector(`.menu-backdrop`) as HTMLElement;
            if (megaMenu && menuBackdrop) {
                megaMenu.setAttribute('style', 'display:block;');
                menuBackdrop.setAttribute('style', 'display:block;');
                this.sempahores.openedMenuId = i;
            }
        }

    }

    closeMenu(i: number): void {
        if (this.sempahores.openedMenuId === -1) {
            return;
        }
        if (isPlatformBrowser(this.platformId)) {
            const megaMenu = this.document.querySelector(`.ui-mega-menu-${i}`) as HTMLElement;
            const menuBackdrop = this.document.querySelector(`.menu-backdrop`) as HTMLElement;
            if (megaMenu) {
                megaMenu.setAttribute('style', 'display:none;');
                menuBackdrop.setAttribute('style', 'display:none;');
                this.sempahores.openedMenuId = -1;
            }
        }

    }

    scrollToSubscribe(): void {
        if (isPlatformBrowser(this.platformId)) {
            const subscribe = this.document.getElementById('subscribe');
            subscribe.scrollIntoView({
                behavior: 'smooth'
            });
        }
    }

}
