<!--faux-mobile-->
<div class="block md:hidden">
  <span *ngIf="isServer">
    <app-header-mobile-ssr></app-header-mobile-ssr>
  </span>
	<span *ngIf="!isServer">
    <app-header-mobile *ngIf="isMobileLayout" [headerMenu]="headerMenu" class="block"></app-header-mobile>
  </span>
</div>

<!--faux-desktop-->
<div class="hidden md:block">
	<span *ngIf="!isServer">
    <app-header-desktop *ngIf="!isMobileLayout" [headerMenu]="headerMenu" class="block"></app-header-desktop>
  </span>
</div>

