<section class="w-full relative md:h-78 flex justify-center max-w-max mx-auto py-20">
	<div class="absolute inset-0 w-full h-full">
		<img *ngIf="contentModule.image"
			 srcset="{{contentModule.image.i_254x451}} 254w, {{ contentModule.image.i_1920x1080 }} 1920w"
			 sizes="(max-width: 1160px) 254px, (min-width: 1160px) 1920px"
			 [src]="contentModule.image.i_1920x1080"
			 class="object-cover object-center w-full h-full"
			 alt="{{ contentModule.name }}">
		<img *ngIf="!contentModule.image"
			 srcset="assets/img/default/img_default_254x451.jpg 254w, assets/img/default/img_default_1920x1080.jpg.jpg 1920w"
			 sizes="(max-width: 1160px) 254px, (min-width: 1160px) 1920px"
			 src="assets/img/default/img_default_1920x1080.jpg.jpg"
			 class="object-cover object-center w-full h-full"
			 alt="{{ contentModule.name }}">

	</div>
	<div class="absolute inset-0 w-full h-full z-10">
		<div class="module-a070-gradient w-full h-full "></div>
	</div>


	<div class="container flex flex-col md:flex-row justify-center md:justify-between items-center space-y-12 md:space-y-0">
		<div *ngFor="let slotable of contentModule.slotables" class="z-10 h-full md:w-4/24">
			<app-slot-a70 [slotable]="slotable"></app-slot-a70>
		</div>
	</div>


</section>
