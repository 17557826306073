<section class="bg-gray-100 w-full pt-10 pb-20 md:py-20">
	<div class="container flex flex-col md:flex-row">
		<div class="w-full md:w-12/24 md:mr-2/24 grid md:grid-cols-2 gap-y-4 md:gap-4 mt-12 md:mt-0">
			<div class="w-full" *ngFor="let slotable of contentModule.slotables">
				<app-slot-a90 [slotable]="slotable"></app-slot-a90>
			</div>
		</div>
		<div class="w-full flex flex-col justify-center md:w-10/24 mt-10 md:mt-0">
			<h2 class="font-700 text-30 md:text-36 leading-120"
				*ngIf="!contentModule.name_hidden">{{ contentModule.name }}</h2>
			<a *ngIf="contentModule.video_url"
			   [appFlLink]="contentModule.video_url"
			   class="w-full mt-6 justify-center items-center md:w-10/24 px-4 py-3 flex border border-gray-300 rounded-18 group hover:bg-gray-black hover:cursor-pointer">
				<span class="text-16 leading-120 font-700 group-hover:text-white">Learn More</span>
				<fa-icon [icon]="faArrowRight" class="ml-4 w-3 h-3 flex justify-center items-center group-hover:text-white"></fa-icon>
			</a>
		</div>
	</div>
</section>
