<a [appFlLink]="slotable.url"
   class="w-full h-full group flex flex-row md:flex-col items-center md:items-start bg-gray-100 p-4 md:p-6 rounded-4 sm:hover:bg-han transition-colors duration-500 ease-in-out">
    <div *ngIf="slotable.image" class="w-14 h-14 flex justify-center items-center rounded-3 bg-white overflow-hidden">
        <img [src]="slotable.image.filename"
             class="w-full h-full object-cover object-center"
             [alt]="slotable.title">
    </div>

    <h3 *ngIf="slotable.title"
        class="ml-4 md:ml-0 md:mt-4 text-18 leading-120 md:text-22 sm:group-hover:text-white font-700 transition-colors duration-500 ease-in-out">
        {{ slotable.title }}
    </h3>

    <div class="hidden md:flex items-center text-14 font-700 text-14 sm:group-hover:text-white underline decoration-transparent sm:group-hover:decoration-white md:mt-12 transition-colors duration-500 ease-in-out">
        <span *ngIf="slotable.url_text"> {{ slotable.url_text }} </span>
        <span *ngIf="!slotable.url_text">Learn More</span>
        <div class="w-6 h-6 rounded-full flex justify-center ml-2 items-center bg-transparent group-hover:bg-white sm:group-hover:ml-4 transition-all duration-500 ease-in-out relative">
            <fa-icon [icon]="faArrowRight" class="w-3 text-gray-black"></fa-icon>
        </div>
    </div>
    <fa-icon [icon]="faArrowRight"
             class="md:hidden w-3 ml-auto sm:group-hover:text-white transition-colors duration-500 ease-in-out"></fa-icon>
</a>
