import {Component, Input, OnInit} from '@angular/core';
import {ContentModuleBaseComponent} from '../content-module-base.component';
import {ContentModule} from '../../models/content-module.model';
import LazyLoad from 'vanilla-lazyload';

@Component({
  templateUrl: './content-module-a020.component.html'
})
export class ContentModuleA020Component implements OnInit, ContentModuleBaseComponent {

  @Input() contentModule: ContentModule;

  ngOnInit(): void {
    const lazyLoadInstances = [];
    const lazyLoadEnter = (element) => {
      const lazyLoad = new LazyLoad({
        container: element
      });
      lazyLoadInstances.push(lazyLoad);
    };

    const lazyLoadVideo = new LazyLoad({
      elements_selector: '.lazy',
      unobserve_entered: true,
      callback_enter: lazyLoadEnter,
      threshold: 0
    });
  }
}
