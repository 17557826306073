<div class="w-full fixed top-0 flex flex-col z-100 border-b border-gray-100 h-26">
    <div class="w-full bg-gray-100">
        <div class="container">
            <div class="w-full h-8 bg-gray-100 flex justify-between items-center font-400">
                <span class="text-10 leading-120 text-gray-300 font-400">
                    Official Website of the Government of Montenegro
                </span>
            </div>
        </div>
    </div>
    <div class="w-full bg-white h-18">
        <div class="container h-full flex justify-between items-center">
            <a routerLink="/" class="block">
                <img src="assets/img/logos/logo.png"
                     width="458"
                     height="60"
                     alt="Digital Nomads"
                     title="Digital Nomads"
                     class="w-[230px] h-[30px]">
            </a>
            <div class="flex items-center">

                <ul class="flex flex-row font-400 space-x-12 mr-12">
                    <li *ngFor="let menuItem of headerMenu ; let i = index;"
                        class="ui-menu-item flex items-center text-gray-500 group hover:cursor-pointer">
                        <a *ngIf="menuItem.type !== 'placeholder'"
                           [appFlLink]="menuItem.url"
                           (mouseenter)="openMenu(i)"
                           class="text-16 leading-120 group-hover:text-han flex items-center">{{ menuItem.title }}
                            <fa-icon [icon]="faChevronDown"
                                     class="group-hover:text-han ml-2 w-3 h-3 flex justify-center items-center"></fa-icon>
                        </a>

                        <span *ngIf="menuItem.type === 'placeholder'"
                              (mouseenter)="openMenu(i)"
                              class="text-16 leading-120 group-hover:text-han flex items-center">{{ menuItem.title }}
                            <fa-icon [icon]="faChevronDown"
                                     class="group-hover:text-han ml-2 w-3 h-3 flex justify-center items-center"></fa-icon>
						</span>

                        <div class="menu-backdrop"></div>

                        <ul *ngIf="menuItem.items && menuItem.items.length"
                            (mouseleave)="closeMenu(i)"
                            class="ui-mega-menu-{{i}} bg-white w-full text-gray-black z-120 absolute left-0 top-[104px] pt-14 pb-16"
                            style="display:none">
                            <div class="container flex flex-col items-start">
                                <a *ngIf="menuItem.type !== 'placeholder'"
                                   [routerLink]="menuItem.link"
                                   [queryParams]="menuItem.query_param"
                                   class="text-left font-700 text-42 leading-120">{{ menuItem.title }}</a>
                                <span *ngIf="menuItem.type === 'placeholder'"
                                      class="text-left font-700 text-42 leading-120">{{ menuItem.title }}</span>
                                <div class="ml-6 mt-10 w-full grid grid-cols-3 gap-y-12 gap-x-10">
                                    <li *ngFor="let item of menuItem.items"
                                        class="w-full flex flex-col">
                                        <div class="w-full flex justify-between items-center items border border border-gray-200 hover:bg-gray-100 p-4 rounded-4 menu-item-level-1">
                                            <a *ngIf="item.type !== 'placeholder'"
                                               [appFlLink]="item.url"
                                               class="text-22 font-700 leading-122">{{ item.title }}</a>
                                            <span *ngIf="item.type === 'placeholder'"
                                                  class="text-22 font-700 leading-122">{{ item.title }}</span>
                                            <fa-icon [icon]="faArrowRight"
                                                     *ngIf="item.type !== 'placeholder'"
                                                     class="w-4 h-4 mr-2 justify-center flex items-center text-white transition-all duration-500 ease-in-out"></fa-icon>
                                        </div>

                                        <ul *ngIf="item.items && item.items.length"
                                            class="flex flex-col text-gray-500 font-400 mt-2 ml-10 space-y-4">
                                            <li *ngFor="let subItem of item.items" class="w-full text-16 leading-120">
                                                <a *ngIf="subItem.type !== 'placeholder'"
                                                   [appFlLink]="subItem.url"
                                                   class="hover:text-han flex justify-start items-center menu-item-level-2">
                                                    {{ subItem.title }}
                                                    <fa-icon [icon]="faArrowRight"
                                                             class="w-4 h-4 justify-center flex items-center ml-3 transition-all duration-500 ease-in-out"></fa-icon>
                                                </a>
                                                <span *ngIf="subItem.type === 'placeholder'"
                                                      class="hover:text-han flex justify-start items-center menu-item-level-2">
													{{ subItem.title }}
												</span>
                                            </li>
                                        </ul>

                                    </li>
                                </div>
                            </div>
                        </ul>
                    </li>
                </ul>

                <a [routerLink]="['/','article','legal-status-for-nomads']" i18n="@@header_apply_now"
                   class="flex justify-center text-16 font-700 leading-120 text-gray-black font-700items-center px-4 py-3 flex border border-gray-300
					   rounded-18 hover:text-white hover:bg-gray-black hover:cursor-pointer">
                    Apply Now
                </a>
            </div>
        </div>
    </div>
</div>
