import { Component, Input, OnInit } from '@angular/core';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-slot-a061',
  templateUrl: './slot-a061.component.html',
  styleUrls: ['./slot-a061.component.scss']
})
export class SlotA061Component implements OnInit {

  @Input() slotable: any;
  faArrowRight = faArrowRight;

  constructor() { }

  ngOnInit(): void {
  }

}
