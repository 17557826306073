import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  @Input() diameter: number = 10;
  height: string;
  width: string;
  constructor() { }

  ngOnInit(): void {
    this.height = `h-${this.diameter}`;
    this.width = `w-${this.diameter}`;
  }

}
