import { Injectable } from '@angular/core';
import { BaseAdapter } from '../../../Library/adapter/adapter.model';
import { WeatherDescription, WeatherDescriptionAdapter } from './weather-description.model';

export class CurrentWeather {
    dt?: number;
    sunrise?: number;
    sunset?: number;
    temp?: number;
    dew_point?: number;
    uvi?: number;
    clouds?: number;
    visibility?: number;
    wind_speed?: number;
    wind_deg?: number;
    weather: WeatherDescription [];

    constructor(data?: {}) {
        Object.assign(this, data);
    }

}

@Injectable({
    providedIn: 'root'
})
export class CurrentWeatherAdapter extends BaseAdapter<CurrentWeather> {
    constructor(private weatherDescriptionAdapter: WeatherDescriptionAdapter) {
        super(CurrentWeather);
    }


    adapt(data: {}): CurrentWeather {
        const item = super.adapt(data);

        if (item.weather) {
            item.weather = this.weatherDescriptionAdapter.adaptArray(item.weather);
        }
        return item;
    }
}

