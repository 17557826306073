<section class="container flex flex-col">
	<header *ngIf="!contentModule.name_hidden" class="flex justify-center items-center">
		<h2 class="font-700 text-30 md:text-36 leading-122 text-gray-black">{{ contentModule.name }}</h2>
	</header>
	<div class="mt-10 md:mt-14 container grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-9 md:gap-10">
		<div class="w-full" *ngFor="let slotable of contentModule.slotables">
			<app-slot-a50 [slotable]="slotable"></app-slot-a50>
		</div>
	</div>

	<a *ngIf="contentModule.video_url"
	   [routerLink]="contentModule.video_url"
	   class="w-full md:w-auto mx-auto mt-16 md:mt-12 p-4 border border-gray-500 rounded-18 flex justify-center md:justify-between">
		<span class="font-700 text-16 leading-120 text-gray-black">Learn More</span>
		<fa-icon [icon]="faArrowRight" class="w-3 h-3 ml-6 text-gray-black"></fa-icon>
	</a>

</section>
