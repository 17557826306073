<section class="w-full">
	<div class="container flex flex-col justify-center items-start md:items-center space-y-8">
		<div class="flex space-x-1">
			<fa-icon *ngFor="let i of [0,1,2,3,4]" [icon]="faStar" class="text-[#EDAD4C] w-5 h-5"></fa-icon>
		</div>

		<h2 *ngIf="contentModule.slotables && contentModule.slotables[0] && contentModule.slotables[0].description"
			class="md:text-20 text-left md:text-center md:w-14/24 leading-120 text-gray-black font-700"
			[innerHtml]="contentModule.slotables[0].description"></h2>
		<div *ngFor="let slotable of contentModule.slotables">
			<app-slot-a140 [slotable]="slotable" [name]="contentModule.name"></app-slot-a140>
		</div>
	</div>
</section>
