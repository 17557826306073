import { Component, Input, OnInit } from '@angular/core';
import { ContentModule } from '../../models/content-module.model';

@Component({
    selector: 'app-content-module-a110',
    templateUrl: './content-module-a110.component.html'
})
export class ContentModuleA110Component implements OnInit {

    @Input() contentModule: ContentModule;

    constructor() {
    }

    ngOnInit(): void {
    }

}
