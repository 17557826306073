<section class="container flex flex-col md:flex-row items-start">
    <div class="order-last md:order-first w-full md:mr-1/24 md:w-14/24 grid gird-cols-1 md:grid-cols-2 gap-y-4 mt-10 md:mt-0 md:gap-4">
        <div class="w-full" *ngFor="let slotable of contentModule.slotables">
            <app-slot-a081 [slotable]="slotable"></app-slot-a081>
        </div>
    </div>

    <div class="order0fist md:order-last w-full flex flex-col md:w-9/24">
        <h2 *ngIf="!contentModule.name_hidden"
            class="font-700 text-28 md:text-36 leading-120 mb-2">{{ contentModule.name }}</h2>
        <div *ngIf="contentModule.description" class="text-gray-700 font-400 text-14 leading-140"
             [innerHTML]="contentModule.description"></div>
    </div>
</section>

