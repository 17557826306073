import { Component, Input, OnInit } from '@angular/core';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';

@Component({
    selector: 'app-slot-a80',
    templateUrl: './slot-a80.component.html',
    styleUrls: ['./slot-a80.component.scss']
})
export class SlotA80Component implements OnInit {
    @Input() slotable: any;

    faArrowRight = faArrowRight;

    constructor() {
    }

    ngOnInit(): void {
    }

}
