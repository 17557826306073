import { Component, Input, OnInit } from '@angular/core';
import { ContentModule } from '../../models/content-module.model';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';

@Component({
    selector: 'app-content-module-a091',
    templateUrl: './content-module-a091.component.html',
    styleUrls: ['./content-module-a091.component.scss']
})
export class ContentModuleA091Component implements OnInit {

    @Input() contentModule: ContentModule;
    faArrowRight = faArrowRight;

    constructor() {
    }

    ngOnInit(): void {
    }

}
