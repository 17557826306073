<div class="w-full flex flex-col relative group">
	<a *ngIf="slotable.url"
	   [appFlLink]="slotable.url" class="absolute inset-0 w-full h-full z-10"></a>
	<div class="relative w-full aspect-3/2 bg-black rounded-4 overflow-hidden ">
		<img *ngIf="slotable.image"
			 class="w-full h-full object-cover object-center transition-all duration-500 ease-in-out group-hover:scale-125"
			 [src]="slotable.image.i_375x250"
			 [alt]="slotable.title">
		<img *ngIf="!slotable.image"
			 class="w-full h-full object-cover object-center transition-all transition-all duration-500 ease-in-out group-hover:scale-125"
			 src="assets/img/default/img_default_375x250.jpg"
			 [alt]="slotable.title">
	</div>
	<h3 *ngIf="slotable.title"
		class="mt-4 text-18 leading-120 font-700 group-hover:text-han group-hover:underline">{{ slotable.title }}</h3>
	<div *ngIf="slotable.description"
		 class="mt-4 font-500 tet-14 leading-140 text-gray-500"
		 [innerHTML]="slotable.description"></div>
</div>
