import { Component, Input, OnInit } from '@angular/core';
import {
    faArrowRight
} from '@fortawesome/pro-light-svg-icons';


@Component({
    selector: 'app-slot-a130',
    templateUrl: './slot-a130.component.html',
    styleUrls: ['./slot-a130.component.scss']
})
export class SlotA130Component implements OnInit {

    @Input() slotable: any;
    faArrowRight = faArrowRight;


    constructor() {
    }

    ngOnInit(): void {
    }

}
