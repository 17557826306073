<section class="w-full max-w-max mx-auto bg-gradient-nomads py-10 sm:py-8 relative overflow-hidden mt-[-80px] md:mt-[-120px]">
	<div class="container relative z-10">
		<div *ngFor="let slotable of contentModule.slotables" class="w-full h-full z-10">
			<app-slot-a120 [slotable]="slotable"></app-slot-a120>
		</div>
	</div>
	<div class="absolute inset-0">
		<picture class="w-full h-full">
            <source srcset="assets/img/module_graphics/gfx_a120_a121_mobile.svg" media="(max-width: 768px)">
            <source srcset="assets/img/module_graphics/gfx_a120_121.svg" media="(min-width: 768px)">
            <img class="w-full h-full object-cover object-center"
                 srcset="assets/img/module_graphics/gfx_a120_a121_mobile.svg"
                 alt="">
        </picture>
	</div>
</section>
