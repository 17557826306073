import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-slot-a90',
    templateUrl: './slot-a90.component.html'
})
export class SlotA90Component implements OnInit {
    @Input() slotable: any;

    constructor() {
    }

    ngOnInit(): void {
    }
}
